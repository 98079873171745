import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SimulationStats } from "../../simulation/types";

interface SimulationOutputState {
  outputs: Map<string, Map<string, SimulationStats>>;
}

export const DEFAULT_SIMULATION_NAME = "Simulation-0";

const defaultOutputs: SimulationOutputState['outputs'] = new Map<string, Map<string, SimulationStats>>([
  [DEFAULT_SIMULATION_NAME, new Map<string, SimulationStats>()],
]);

const simulationOutputSlice = createSlice({
  name: "simulationOutput",
  initialState: {
    outputs: defaultOutputs,
  } as SimulationOutputState,
  reducers: {
    replaceWith(state, action: PayloadAction<SimulationOutputState['outputs']>) {
      state.outputs = action.payload;
    },
    reset(state) {
      state.outputs = defaultOutputs;
    }
  },
});

export const simulationOutputActions = simulationOutputSlice.actions;

export default simulationOutputSlice.reducer;