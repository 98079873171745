import React from "react";
import { Button, Divider } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import AssumptionCard from "../../components/AssumptionCard";
import { addAssumption } from "../../redux/slices/variables-slice";
import { usePageStyles } from "../styles";
import useActionsDisabled from "../../hooks/useActionsDisabled";

const AssumptionPage: React.FC = () => {
  const styles = usePageStyles();
  const assumptions = useAppSelector((state) => state.variables.assumptions);
  const dispatch = useAppDispatch();

  const actionsDisabled = useActionsDisabled();

  function handleAddAssumption() {
    window.scrollTo({
      top: 96,
      behavior: "smooth",
    });
    dispatch(addAssumption());
  }

  return (
    <div className={styles.root}>
      <div className={styles.newButtonSticky}>
        <div className={styles.newButtonContainer}>
          <Button
            className={styles.newButton}
            icon={<AddRegular fontSize={16} />}
            onClick={handleAddAssumption}
            disabled={actionsDisabled}
          >
            Define New Assumption
          </Button>
        </div>
      </div>

      {assumptions.map((assumption) => (
        <AssumptionCard
          key={assumption.id}
          id={assumption.id}
          disabled={actionsDisabled}
        />
      ))}

      <Divider />
    </div>
  );
};

export default AssumptionPage;
