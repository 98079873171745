import { InvalidBoundCellError, InvalidSimulationError } from "./errors";
import { Assumption, Correlation, Decision, Forecast } from "./types";

export const MAX_DECISIONS = 2
export function median(values: number[]): number {

    if (values.length === 0) {
      return 0.0;
    }
  
    // Sorting values, preventing original array
    // from being mutated.
    values = [...values].sort((a, b) => a - b);
  
    const half = Math.floor(values.length / 2);
  
    return (values.length % 2
      ? values[half]
      : (values[half - 1] + values[half]) / 2
    );
  }
  
  export const standardDeviation = (arr, usePopulation = false) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    const stddev = Math.sqrt(
      arr
        .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
        .reduce((acc, val) => acc + val, 0) /
        (arr.length - (usePopulation ? 0 : 1))
    );
    //console.log(`stddev=${stddev}, avg=${mean}`);
    return stddev;
  };

  export const generateDecisionTable = (k: number, table: number[][], tmp: number[], sols: number[][]) => {
    if (k === table.length) {
        let v = []
        for (let i = 0; i < tmp.length; i++) {
            v.push(tmp[i]);
        }
        sols.push(v);
        //console.log(` sols ${sols}`);
        return;
    } else {
        for (var i = 0; i < table[k].length; i++) {
            tmp[k] = table[k][i];
            generateDecisionTable (k + 1, table, tmp, sols);
            tmp[k] = 0;
        }
    }
  };


const NUMBER_OF_TRIALS_DEFAULT = 2000;
const NUMBER_OF_TRIALS_DECISION_TABLE_DEFAULT = 1000;

export function getDefaultNumberOfTrials(runDecisionTable?: boolean) {
  if (runDecisionTable) {
    return NUMBER_OF_TRIALS_DECISION_TABLE_DEFAULT;
  }

  return NUMBER_OF_TRIALS_DEFAULT;
}

export function getNthColumn(startColumn: string, n: number) {
  // Convert start column from base-26 string to a zero-indexed number
  let columnNumber = 0;
  for (let i = 0; i < startColumn.length; i++) {
      columnNumber = columnNumber * 26 + (startColumn.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
  }

  // Calculate the new column index (zero-indexed)
  columnNumber += (n - 1);

  // Convert the zero-indexed number back to a column string
  let columnName = '';
  while (columnNumber >= 0) {
      let remainder = columnNumber % 26;
      columnName = String.fromCharCode(remainder + 'A'.charCodeAt(0)) + columnName;
      columnNumber = Math.floor((columnNumber - remainder) / 26) - 1;
  }

  return columnName;
}

export function isSimulationValid (assumptions: Assumption[], forecasts: Forecast[], decisions: Decision[], correlations: Correlation[], numberOfTrials?: number) {
  if (!assumptions || assumptions.length == 0) {
    console.log("no assumptions");
    throw new InvalidSimulationError("No assumption defined!");
  }
  if (!forecasts || forecasts.length == 0) {
    console.log("no forecasts");
    throw new InvalidSimulationError("No forecasts defined!");
  }

  if (decisions && decisions.length > MAX_DECISIONS) {
    console.log("max decisions exceeded");
    throw new InvalidSimulationError("Excedeed maximum allowed number of Decisions (" + MAX_DECISIONS + ")!");
  }

  correlations.forEach((c, index) => {
    let cell = c.FirstAssumptionCell;
    if (undefined === (assumptions.find((a) => a.BoundCell == cell))) {
      throw new InvalidSimulationError(`correlation [${index}] has undefined assumption on cell= ${cell}`);
    }
    cell = c.SecondAssumptionCell;
    if (undefined === (assumptions.find((a) => a.BoundCell == cell))) {
      throw new InvalidSimulationError(`correlation [${index}] has undefined assumption on cell= ${cell}`);
    }
  });

  if (!numberOfTrials) {
    return;
  }

  if (numberOfTrials < 1 || !Number.isInteger(numberOfTrials)) {
    throw new InvalidBoundCellError("Simulation: Number of trials must be a defined, positive integer");
  }
}
