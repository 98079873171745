import React, { FC } from 'react';
import { useCardStyles } from '../styles';
import { useAppSelector } from '../../redux/hooks';
import DeleteButton from '../DeleteButton';
import ToggleCollapsedButton from '../ToggleCollapsedButton';
import { Body1, Card, CardHeader } from '@fluentui/react-components';
import { decisionParamsDisplayName } from '../../enums/decisions';

interface Props {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

const CollapsedDecisionCard: FC<Props> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();

  const decision = useAppSelector((state) =>
    state.variables.decisions.find((c) => c.id === id)
  );

  return (
    <Card>
      <CardHeader
        header={
          <Body1 className={styles.header}>
            <span>
              <b>{decision.cell}</b>
            </span>

            <div className={styles.cardActions}>
              <ToggleCollapsedButton id={id} />
              <DeleteButton disabled={disabled} onDelete={onDelete}>
                Remove Decision
              </DeleteButton>
            </div>
          </Body1>
        }
      />
      <Body1 className={styles.rowBody}>
        {decision.decisionParams?.map(param => (
          <div key={param.name}>
            <b>{param.cell}</b> - {decisionParamsDisplayName[param.name]}
          </div>
        ))}
      </Body1>
    </Card>


  );
};

export default CollapsedDecisionCard;