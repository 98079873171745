import React from "react";
import {
  TableSwitch24Regular,
  BracesVariable24Filled,
  ChartMultiple24Regular,
  ClipboardSearch24Regular,
  StreamOutput20Filled,
  BinFull20Regular,
  BinFull20Filled,
  BookPulse20Regular,
} from "@fluentui/react-icons";

export interface IRouteItem {
  icon: React.JSX.Element;
  primaryText: string;
  linkTo: string;
}

export enum MainRoutesEnum {
  ASSUMPTION_TAB = "/assumption-tab",
  FORECAST_TAB = "/forecast-tab",
  DECISION_TAB = "/decision-tab",
  SIMULATE_TAB = "/simulate-tab",
  ANALYZE_TAB = "/analyze-tab"
}

export enum AssumptionTabPages {
  ASSUMPTIONS = `${MainRoutesEnum.ASSUMPTION_TAB}/assumptions`,
  CORRELATIONS = `${MainRoutesEnum.ASSUMPTION_TAB}/corelations`,
}

export enum DecisionTabPages {
  DECISIONS = `${MainRoutesEnum.DECISION_TAB}/decisions`,
  DECISION_TABLES = `${MainRoutesEnum.DECISION_TAB}/decision-tables`
}

export const routeItems: IRouteItem[] = [

  {
    icon: <BracesVariable24Filled />,
    primaryText: "Assumption",
    linkTo: MainRoutesEnum.ASSUMPTION_TAB,
  },
  {
    icon: <StreamOutput20Filled />,
    primaryText: "Forecast",
    linkTo: MainRoutesEnum.FORECAST_TAB,
  },
  {
    icon: <ChartMultiple24Regular />,
    primaryText: "Simulate",
    linkTo: MainRoutesEnum.SIMULATE_TAB,
  },
  {
    icon: <ClipboardSearch24Regular />,
    primaryText: "Analyze",
    linkTo: MainRoutesEnum.ANALYZE_TAB,
  },
  {
    icon: <BinFull20Filled />,
    primaryText: "Decisions",
    linkTo: MainRoutesEnum.DECISION_TAB,
  },
];
