import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loadAssumptions,
  setAssumptionProperties,
  setCorrelationProperties,
  setDecisionProperties,
  setForecastProperties,
} from "./variables-slice";
import { store } from "../store";

interface CollapsedState {
  [key: string]: boolean;
}

const initialState: CollapsedState = {};

const collapsedSlice = createSlice({
  name: "collapsed",
  initialState,
  reducers: {
    set(state, action: PayloadAction<{id: string, collapsed: boolean }>) {
      const { id, collapsed } = action.payload;
      state[id] = collapsed;
    },
    load(state, action: PayloadAction<CollapsedState>) {
      Object.keys(action.payload).forEach(id => {
        state[id] = action.payload[id];
      });
    }
  },
  extraReducers(builder) {
    builder.addCase(setAssumptionProperties, (state, action) => {
      state[action.payload.id] = true;
    });

    builder.addCase(setDecisionProperties, (state, action) => {
      state[action.payload.id] = true;
    });

    builder.addCase(setForecastProperties, (state, action) => {
      state[action.payload.id] = true;
    });

    builder.addCase(setCorrelationProperties, (state, action) => {
      state[action.payload.id] = true;
    });
  },
});

export const collapsedActions = collapsedSlice.actions;

export default collapsedSlice.reducer;