export class InvalidBoundCellError implements Error {
  constructor(public message: string, public name: string = "UpdateCellError", public stack?: string) {}
}

export class InvalidSimulationError implements Error {
  constructor(public message: string, public name: string = "InvalidSimulationError", public stack?: string) {}
}

export class UpdateCellError implements Error {
  constructor(public message: string, public name: string = "UpdateCellError", public stack?: string) {}
}

export class InvalidDisplayName implements Error {
  constructor(public message: string, public name: string = "InvalidDisplayName", public stack?: string) {}
}

export class InvalidRangeError implements Error {
  constructor(public message: string, public name: string = "InvalidRangeError", public stack?: string) {}
}

export class NotImplementedError implements Error {
  constructor(public message: string, public name: string = "NotImplementedError", public stack?: string) {}
}

export class BuilderNotFoundError implements Error {
  constructor(public message: string, public name: string = "BuilderNotFoundError", public stack?: string) {}
}

export class InvalidCorrelationParamsError implements Error {
  constructor(public message: string, public name: string = "InvalidCorrelationParamsError", public stack?: string) {}
}

export class AssumptionInMultipleCorrelationsError implements Error {
  constructor(public message: string, public name: string = "AssumptionInMultipleCorrelations", public stack?: string) {}
}

export class InvalidDecisionInterval implements Error {
  constructor(public message: string, public name: string = "InvalidDecisionInterval", public stack?: string) {}
}

export class InvalidCellNumber implements Error {
  constructor(public message: string, public name: string = "InvalidCellNumber", public stack?: string) {}
}

export class InvalidDistributionParams implements Error {
  constructor(public message: string, public name: string = "InvalidDistributionParams", public stack?: string) {}
}

export class InvalidCorrelationFactor implements Error {
  constructor(public message: string, public name: string = "InvalidCorrelationFactor", public stack?: string) {}
}