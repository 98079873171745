import React, { FC } from 'react';
import * as Yup from "yup";
import InputWithLabel from '../../components/InputWithLabel';
import { Formik } from 'formik';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  wrapper: {
    width: '150px',
    height: '80px'
  }
});

interface Props {
  value: number;
  disabled?: boolean;
  onChange: (_: number) => void;
  max?: number;
}

const NumberOfBinsInput: FC<Props> = ({
  value,
  onChange,
  disabled,
  max = 100,
}) => {
  const styles = useStyles();

  return (
    <Formik
      initialValues={{ numberOfBins: value }}
      onSubmit={(values) => {
        onChange(values.numberOfBins)
      }}
      validationSchema={Yup.object({
        numberOfBins: Yup.number()
          .min(0, `Between 0 and ${max}`)
          .max(max, `Between 0 and ${max}`)
          .integer('Integers only')
      })}
    >
      {({ handleChange, handleSubmit, handleBlur, validateForm, errors, touched }) => (
        <div className={styles.wrapper}>
          <InputWithLabel
            id="numberOfBins"
            name="numberOfBins"
            label="Number Of Bins"
            autoComplete="off"
            onBlur={handleBlur}
            error={touched.numberOfBins && errors.numberOfBins}
            disabled={disabled}
            type="number"
            value={value.toString()}
            onChange={e => {
              handleChange(e);
              setTimeout(() => {
                validateForm().then(() => handleSubmit());
              });
            }}
          />
        </div>
      )}
    </Formik>
  );
};

export default NumberOfBinsInput;