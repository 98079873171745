import { SimulationRecorder, SimulationStats } from "./types";
import { median, standardDeviation } from "./utils";

export class SimpleRecorder implements SimulationRecorder {
  constructor(
    private forecastNames: string[],
    private num_decisions: number,
    private num_assumptions: number,
    private trial_values: Map<string, number[][]> = new Map<string, number[][]>()
  ) {}

  record_trial(
    simulationName: string,
    currentDecisionsValues: number[],
    currentAssumptionsValues: number[],
    forecastValues: number[]
  ): void {
    if (this.trial_values.has(simulationName) === false) {
      this.trial_values.set(simulationName, []);
    }
    let elems = currentDecisionsValues.concat(currentAssumptionsValues.concat(forecastValues));
    this.trial_values.get(simulationName).push(elems);
  }
  reset_recording(): void {
    this.trial_values.clear();
  }
  get_trial_values(simulationName: string, idx: number): number[] {
    return this.trial_values.get(simulationName)[idx];
  }
  get_trial_assumption_values(simulationName: string, idx: number): number[] {
    return this.trial_values
      .get(simulationName)
      [idx].slice(this.num_decisions, this.num_decisions + this.num_assumptions - 1);
  }
  get_trial_forecast_values(simulationName: string, idx: number): number[] {
    return this.trial_values.get(simulationName)[idx].slice(this.num_decisions + this.num_assumptions);
  }

  get_forecast_values_stats(): Map<string, Map<string, SimulationStats>> {
    let statsMap = new Map<string, Map<string, SimulationStats>>();
    let keys = Array.from(this.trial_values.keys());

    for (let k = 0; k < keys.length; k++) {
      let simulationName = keys[k];
      var forecast_values = [];
      let v = this.get_trial_forecast_values(simulationName, 0);

      // create an empty array for each forecast
      for (let i = 0; i < v.length; i++) {
        forecast_values.push([]);
      }

      for (let i = 0; i < this.trial_values.get(simulationName).length; i++) {
        // get the values of all forecasts in this trial
        let v = this.get_trial_forecast_values(simulationName, i);

        // store each forecast value in its corresponding array
        for (let j = 0; j < v.length; j++) {
          forecast_values[j].push(v[j]);
        }
      }

      let stats = new Map<string, SimulationStats>();
      for (let i = 0; i < forecast_values.length; i++) {
        const sum = forecast_values[i].reduce((a, b) => a + b, 0);
        const avg = sum / (forecast_values[i].length * 1.0) || 0;
        console.log(`forecast sum ${sum}`);
        console.log(`forecast avg ${avg}`);
        stats.set(
          this.forecastNames[i],
          new SimulationStats(
            forecast_values[i],
            avg,
            standardDeviation(forecast_values[i]),
            median(forecast_values[i])
          )
        );
      }

      statsMap.set(simulationName, stats);
      // console.log(`stats map: ${Array.from(statsMap.keys())}`);
      // console.log(`stats_map: ${Array.from(statsMap.get(simulationName).keys())}`);
    }

    return statsMap;
  }

  dump(simulationName: string) {
    for (let i = 0; i < this.trial_values.get(simulationName).length; i++) {
      console.log("-- trial ", i);
      console.log(this.trial_values.get(simulationName)[i]);
    }
  }
}
