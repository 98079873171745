import { SimulationParams } from "./probdist";
import { InvalidCorrelationFactor, InvalidDecisionInterval, InvalidDistributionParams } from "./errors";
import { MAX_DECISIONS, generateDecisionTable } from "./utils";
import { generateCorrelatedSamples, setCorrelationRandomSeed } from "./correlation";
import { DEFAULT_SIMULATION_NAME, simulationOutputActions } from "../redux/slices/simulation-output-slice";
import { ExcelAssumptionUpdate } from "./updater";
import { store } from "../redux/store";
import { Assumption, Correlation, Decision, Forecast, Simulation, SimulationRecorder, SimulationStats } from "./types";
import { SimpleRecorder } from "./recorder";

class SimpleSimulation implements Simulation {
  constructor(
    public Decisions: Decision[],
    public Assumptions: Assumption[],
    public Correlations: Correlation[],
    public Forecasts: Forecast[],
    public recorder: SimulationRecorder = undefined,
  ) {
    if (undefined == recorder) {
      let forecastNames = Forecasts.map((f) => f.BoundCell);
      recorder = new SimpleRecorder(
        forecastNames,
        Decisions.length < MAX_DECISIONS ? MAX_DECISIONS : Decisions.length,
        Assumptions.length
      );
    }
  }

  async *Run(simparams: SimulationParams, assumptionUpdater: ExcelAssumptionUpdate) {
    setCorrelationRandomSeed(simparams.Seed);

    let correlationFactors = await this.GetCorrelationFactors(assumptionUpdater);
    let allDecisionValues = await this.GetAllDecisionValues(simparams, assumptionUpdater);
    let assumptionFactories = await this.GetAssumptionDistributionFactories(this.Assumptions, simparams, assumptionUpdater);

    for (
      let decisionIndex = 0;
      decisionIndex < allDecisionValues.length;
      ++decisionIndex
    ) {
      let simName = this.GetSimulationNameFor(allDecisionValues[decisionIndex]);

      const { assumptionValues, trialDecisionValues } = this.ComputeValuesForDecision(
        simparams,
        allDecisionValues[decisionIndex],
        correlationFactors,
        assumptionFactories
      );

      for(let assumptionIndex = 0; assumptionIndex < assumptionValues.length; ++assumptionIndex) {
        assumptionUpdater.UpdateAssumptions(this.Assumptions,  assumptionValues[assumptionIndex]);
        assumptionUpdater.UpdateDecisions(this.Decisions,  trialDecisionValues[assumptionIndex]);
        assumptionUpdater.Recalculate();

        let forecastValues = await assumptionUpdater.ExtractForecastValues(this.Forecasts);

        this.recorder.record_trial(
          simName,
          trialDecisionValues[assumptionIndex],
          assumptionValues[assumptionIndex],
          forecastValues
        );
        
        yield {
          simulationLength: allDecisionValues.length * simparams.NumTrials,
          simulationStep: Math.min(
            decisionIndex * simparams.NumTrials + assumptionIndex + 1,
            allDecisionValues.length * simparams.NumTrials
          ),
        };
      }
    }
  }

  async *RunFast(simparams: SimulationParams, assumptionUpdater: ExcelAssumptionUpdate) {
    setCorrelationRandomSeed(simparams.Seed);

    let correlationFactors = await this.GetCorrelationFactors(assumptionUpdater);
    let allDecisionValues = await this.GetAllDecisionValues(simparams, assumptionUpdater);
    let assumptionFactories = await this.GetAssumptionDistributionFactories(this.Assumptions, simparams, assumptionUpdater);

    let statsMap = new Map<string, Map<string, SimulationStats>>();

    for (
      let decisionIndex = 0;
      decisionIndex < allDecisionValues.length;
      ++decisionIndex
    ) {
      let simName = this.GetSimulationNameFor(allDecisionValues[decisionIndex]);

      let processingSheet = assumptionUpdater.CreateSheetForProcessing();

      const { assumptionValues, trialDecisionValues } = this.ComputeValuesForDecision(
        simparams,
        allDecisionValues[decisionIndex],
        correlationFactors,
        assumptionFactories
      );
      
      let cellMapper = await assumptionUpdater.FillInDataForProcessing(
        processingSheet,
        this.Forecasts,
        this.Assumptions,
        this.Decisions,
        assumptionValues,
        trialDecisionValues,
      );

      const {
        forecastValues,
        forecastStats
       } = await assumptionUpdater.ExtractForecastValuesAndStats(
        processingSheet,
        this.Forecasts,
        cellMapper,
        assumptionValues.length
      );

      let stats = new Map<string, SimulationStats>();
      forecastStats.forEach(({ avg, stdev, median}, forecastIndex) => {
        stats.set(this.Forecasts[forecastIndex].BoundCell, new SimulationStats(
          forecastValues[forecastIndex],
          avg,
          stdev,
          median
        ))
      })
      statsMap.set(simName, stats);

      assumptionUpdater.UpdateAssumptions(this.Assumptions,  assumptionValues[assumptionValues.length - 1]);
      assumptionUpdater.UpdateDecisions(this.Decisions,  trialDecisionValues[trialDecisionValues.length - 1]);

      await assumptionUpdater.DeleteProcessingSheet(processingSheet);

      yield {
        simulationLength: allDecisionValues.length * simparams.NumTrials,
        simulationStep: Math.min(
          assumptionValues.length * (decisionIndex + 1),
          allDecisionValues.length * simparams.NumTrials
        ),
      };
    }

    store.dispatch(simulationOutputActions.replaceWith(statsMap));
  }

  ComputeValuesForDecision(
    simparams: SimulationParams, 
    trialDecision: number[],
    correlationFactors: number[],
    { pdfactories, uncorrelatedAssumptionsIndexes }: Awaited<ReturnType<typeof this.GetAssumptionDistributionFactories>>
  ) {
    let assumptionValues: number[][] = [];
    let trialDecisionValues = new Array(simparams.NumTrials).fill(trialDecision);

    let correlatedSamples = [];
    console.log(`[Corr] Start correlations ${this.Correlations.length}`);
    for (let i = 0; i < this.Correlations.length; i++) {
      let probSpec1 = this.Assumptions
        .find(a => a.BoundCell === this.Correlations[i].FirstAssumptionCell).ProbabilityDistribution;

      let probSpec2 = this.Assumptions
        .find(a => a.BoundCell === this.Correlations[i].SecondAssumptionCell).ProbabilityDistribution;

      let samples = generateCorrelatedSamples (simparams.NumTrials, simparams.Seed, correlationFactors[i], [probSpec1, probSpec2]);
      correlatedSamples.push(samples[0]);
      correlatedSamples.push(samples[1]);
      console.log(`[Corr] generated correlated samples for ${this.Correlations[i].FirstAssumptionCell} and ${this.Correlations[i].SecondAssumptionCell}`);
    }

    for (let i = 0; i < simparams.NumTrials; i++) {
      //generate assumption values
      // console.log(`Simulation step: ${i} ...`);
      let currentUncorrelatedAssumptionsValues: number[] = pdfactories.map((pf) => pf());

      // console.log(`[Corr] generating uncorrelated samples for ${uncorrelatedAssumptionsIndex}`);
      let uncorrIdx = 0;
      let currentAssumptionsValues = [];
      for (let j = 0; j < this.Assumptions.length; j++) {
        if (uncorrelatedAssumptionsIndexes.includes(j)) {
          currentAssumptionsValues.push(currentUncorrelatedAssumptionsValues[uncorrIdx]);
          uncorrIdx++;
        } else {
          currentAssumptionsValues.push(correlatedSamples[this.getCorrelatedValueIndex(j)][i]);
        }
      }
      assumptionValues.push(currentAssumptionsValues);
      // console.log(`Simulation step: ${i} done.`);
    }

    return { assumptionValues, trialDecisionValues };
  }

  async GetAssumptionDistributionFactories(assumptions: Assumption[], simparams: SimulationParams, assumptionUpdater: ExcelAssumptionUpdate) {
    for (var a of assumptions) {
      console.log("setupParams cell=", a.BoundCell, "pd=", a.ProbabilityDistribution.Name);
      try {
        await a.ProbabilityDistribution.SetupParams(assumptionUpdater);
      } catch (error) {
        throw new InvalidDistributionParams(`Distribution parameters for ${a.BoundCell}: ${error.message}`);
      }
      console.log("completed setupParams cell=", a.BoundCell, "pd=", a.ProbabilityDistribution.Name);
    }

    let [uncorrelatedAssumptions, uncorrelatedAssumptionsIndexes] = this.getUncorrelatedAssumptions();
    let pdfactories: (() => number)[] = uncorrelatedAssumptions.map((a) => {
      return a.ProbabilityDistribution.Factory(simparams);
    });
    return {
      pdfactories,
      uncorrelatedAssumptionsIndexes,
    }
  }

  async GetAllDecisionValues(simparams: SimulationParams, assumptionUpdater: ExcelAssumptionUpdate) {
    const distinctDecisionValues = await this.GetDistinctDecisionValues(simparams, assumptionUpdater);

    let tmp = [];
    for (var i = 0; i < distinctDecisionValues.length; i++) {
      tmp.push(0);
    }

    const result:number[][] = [];
    // this is where we generate all possible decision combinations
    generateDecisionTable(0, distinctDecisionValues, tmp, result);

    return result;
  }

  async GetDistinctDecisionValues(simparams: SimulationParams, assumptionUpdater: ExcelAssumptionUpdate) {
    let distinctDecisionValues: number[][] = [];
    for (let i = 0; i < this.Decisions.length; i++) {
      let currDecisionValues: number[] = [];

      let decisionParams = await assumptionUpdater.single_values([
        this.Decisions[i].MinCell,
        this.Decisions[i].MaxCell,
        this.Decisions[i].StepCell,
      ]);

      if ((decisionParams[0] + decisionParams[2] > decisionParams[1]) || (decisionParams[2] <= 0)) {
        throw new InvalidDecisionInterval(`Decision parameters for ${this.Decisions[i].BoundCell} would yield one or no values!`);
      }

      if (simparams.RunDecisionTable === true) {
        // decision has three parameters: min, max, step
        for (let j = decisionParams[0]; j <= decisionParams[1]; j = j + decisionParams[2]) {
          currDecisionValues.push(j);
        }
      } else {
        currDecisionValues.push(decisionParams[0]);
      }

      distinctDecisionValues.push(currDecisionValues);
    }
    console.log(`Decision values: ${distinctDecisionValues}`);

    for (let j = distinctDecisionValues.length; j < MAX_DECISIONS; j++) distinctDecisionValues.push([0]);
    return distinctDecisionValues;
  }

  async GetCorrelationFactors(assumptionUpdater: ExcelAssumptionUpdate) {
    const correlationFactors = [];
    for (let i = 0; i < this.Correlations.length; i++) {
      let factor = await assumptionUpdater.single_values([this.Correlations[i].BoundCell]);
      if (factor[0] > 1 || factor[0] <= 0) {
        throw new InvalidCorrelationFactor(`Correlation factor at ${this.Correlations[i].BoundCell} should be in (0,1] range!`);
      }
      correlationFactors.push(factor[0]);
    }
    console.log(`[Corr] correlation_factors ${correlationFactors}`);
    return correlationFactors;
  }

  GetSimulationNameFor(trialDecision: number[]) {
    let simName = this.Decisions.length === 0 ? DEFAULT_SIMULATION_NAME : "Simulation";
    for (let i = 0; i < this.Decisions.length; i++) {
      simName =
        simName + "-" + this.Decisions[i].BoundCell + "=" + trialDecision[i];
    }
    return simName;
  }

  // TODO - maybe revise this system. Right now we are filtering all the uncorrelated assumptions
  // and saving their indexes in the original assumption array.
  // These indexes are necessary to make sure we correctly create the assumption values for each trial.
  getUncorrelatedAssumptions () {
    let uncorrelatedAssumptions = [];
    let uncorrelatedAssumptionsIndex = []
    for (let i = 0; i < this.Assumptions.length; i++) {
      let cellName = this.Assumptions[i].BoundCell;
      let found = false;
      for (let j = 0; j < this.Correlations.length; j++) {
        if (this.Correlations[j].FirstAssumptionCell === cellName || this.Correlations[j].SecondAssumptionCell === cellName) {
          found = true;
        }
      }
      if (found === false) {
        uncorrelatedAssumptions.push(this.Assumptions[i]);
        uncorrelatedAssumptionsIndex.push(i);
      }
    }
    return [uncorrelatedAssumptions, uncorrelatedAssumptionsIndex];
  }

  getCorrelatedValueIndex (idx: number) {
    for (let i = 0; i < this.Correlations.length; i++) {
      if (this.Correlations[i].FirstAssumptionCell === this.Assumptions[idx].BoundCell) {
        return i * 2;
      }
      if (this.Correlations[i].SecondAssumptionCell === this.Assumptions[idx].BoundCell) {
        return i * 2 + 1;
      }
    }
    return -1;
  }
}

export default SimpleSimulation;
