import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  PropsWithChildren,
} from "react";
import { makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import {ConfigProvider, Slider} from 'antd';
import { excelColors } from "../../constants/colors";

interface IMultiRangeSliderProps {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  disabled?: boolean;
  onChange: (props: { min: number; max: number }) => void;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    margin: "0 30px 0 40px",
  },
  sliderContainer: {
    alignSelf: "center",
    width: "fit-content",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  slider: {
    position: "relative",
    width: "300px",
  },
  sliderTrack: {
    position: "absolute",
    borderRadius: "10px",
    height: "5px",
    backgroundColor: excelColors.histogramInactive,
    width: "100%",
    zIndex: 1,
  },
  sliderRange: {
    position: "absolute",
    borderRadius: "10px",
    height: "5px",
    backgroundColor: excelColors.histogramActive,
    zIndex: 2,
  },
  sliderValue: {
    color: "#333",
    fontSize: "14px",
    flex: "1",
    display: 'flex',
  },
  sliderValuesWrapper: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: "1rem",
  },
  inputField: {
    ...shorthands.padding("4px", "8px"),
    ...shorthands.border("1px", "solid", "#ccc"),
    borderRadius: "4px",
    fontSize: "14px",
    maxWidth: "180px",
    width: '100%',
  },
});

const MultiRangeSlider: React.FC<PropsWithChildren<IMultiRangeSliderProps>> = ({
  min,
  max,
  minValue,
  maxValue,
  onChange,
  children,
  disabled,
}) => {
  const styles = useStyles();
  let roundedMin = Number(min.toFixed(2));
  let roundedMax = Number(max.toFixed(2));

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <ConfigProvider
           theme={{
            components: {
              Slider: {
                dotActiveBorderColor: excelColors.histogramActive,
                trackBg: excelColors.histogramActive
              }
            }
          }}
        >
          <Slider
            range
            min={roundedMin}
            max={roundedMax}
            step={(roundedMax - roundedMin) / 100}
            value={[minValue, maxValue]}
            style={{ width: '300px' }}
            onChange={([min, max]) => {
              onChange({ min: Number(min.toFixed(2)), max: Number(max.toFixed(2)) });
            }}
            disabled={disabled}
            tooltip={{open: false}}
          />
        </ConfigProvider>
      </div>

      <div className={styles.sliderValuesWrapper}>
        <div
          className={styles.sliderValue}
          style={{ justifyContent: 'flex-end' }}
        >
          <input
            type="number"
            value={minValue}
            min={roundedMin}
            max={roundedMax}
            disabled={disabled}
            className={styles.inputField}
            onChange={(e) => onChange({ min: Number(e.target.value), max: maxValue })}
          />
        </div>

        {children}

        <div
          className={styles.sliderValue}
          style={{ justifyContent: 'flex-start' }}
        >
          <input
            type="number"
            value={maxValue}
            min={roundedMin}
            max={roundedMax}
            disabled={disabled}
            className={styles.inputField}
            onChange={(e) => onChange({ min: minValue, max: Number(e.target.value) })}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
