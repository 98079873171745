import * as React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { makeStyles, tokens } from "@fluentui/react-components";
import { useHistory, useLocation } from "react-router-dom";
import { routeItems } from "../../routes/routes";
import { useAppSelector } from "../../redux/hooks";
import { SimulationStateEnum } from "../../redux/slices/simulate-slice";
import Tabs from "../Tabs";
import Tab from "../Tabs/Tab";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "stretch",
    flexDirection: "column",
    rowGap: "20px",
  },
  simple__text: {
    color: "inherit",
    textDecoration: "none",
  },
  tab_list: {
    width: "100%",
    marginTop: "8px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: tokens.colorNeutralBackground1,
    // filter: `drop-shadow(0 23px 9px ${tokens.colorNeutralBackground1})`,
  },
  main_frame: {
    padding: "1rem",
  },
});

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleTabClick = (value: string) => () => {
    history.push(value);
  };

  const simulateStep = useAppSelector((state) => state.simulate.state);

  const currentTab = React.useMemo(() => {
    return routeItems.find(item => location.pathname.startsWith(item.linkTo));
  }, [location.pathname]);

  let isRunning = [
    SimulationStateEnum.RUNNING,
    SimulationStateEnum.RUNNING_FAST,
    SimulationStateEnum.CANCELING_FAST,
  ].includes(simulateStep);

  return (
    <div>
      <div className={styles.wrapper}>
        <Tabs>
          {routeItems.map((item) => (
            <Tab
              key={item.linkTo}
              icon={item.icon}
              selected={currentTab?.linkTo === item.linkTo}
              onClick={handleTabClick(item.linkTo)}
              disabled={isRunning}
            >
              {item.primaryText}
            </Tab>
          ))}
        </Tabs>

        <div className={styles.main_frame}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
