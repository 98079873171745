import { useEffect, useState } from "react";

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    const currentRef = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        setDimensions(entries[0].contentRect);
      }
    });
    resizeObserver.observe(currentRef);
    return () => resizeObserver.unobserve(currentRef);
  }, [ref]);

  return dimensions;
};

export default useResizeObserver;
