import React, { useEffect, useState } from "react";
import { makeStyles, Option, OptionOnSelectData, Title3, Button, SelectOnChangeData } from "@fluentui/react-components";
import { createReport, tryCatch } from "../../taskpane";
import { useAppSelector } from "../../redux/hooks";
import SelectWithLabel from "../../components/SelectWithLabel";
import { SimulationStats } from "../../simulation/types";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
  slider: {
    display: "flex",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    gap: "1rem",
  },
});

export function getForecasts (stats: Map<String, Map<string, SimulationStats>>) {
    let simName = Array.from(stats.keys())[0];
    return Array.from(stats.get(simName).keys());
}

const DecisionTablePage: React.FC = () => {
  const styles = useStyles();
  const stats = useAppSelector(state => state.simulationOutput.outputs);

  const forecasts = getForecasts(stats);

  const [selectedStat, setSelectedStat] = useState<string | undefined>("mean");
  const [selectedForecast, setSelectedForecast] = useState<string | undefined>(forecasts[0]);

  useEffect(() => {
    setSelectedForecast(forecasts[0]);
  }, [forecasts[0]]);
  const hasDecisions = Array.from(stats.keys()).length > 1;

  const changeSelectedStat = (_, data: SelectOnChangeData) => {
    setSelectedStat(data.value);
  };

  const changeSelectedForecast = (_, data: SelectOnChangeData) => {
    setSelectedForecast(data.value);
  };

  const report = async () => {
    tryCatch(async () => {
      await createReport(selectedForecast, selectedStat);
    });
  };

  return (
    <div className={styles.root}>
      
      <Title3>Statistics </Title3>

      <SelectWithLabel
        id="forecast-dropdown"
        name="forecast-dropdown"
        label="Select a Forecast"
        value={selectedForecast || ''}
        onChange={changeSelectedForecast}
      >
        <option disabled selected={!selectedForecast}>
          Select a Forecast
        </option>
        {forecasts.map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </SelectWithLabel>

      <br />

      <SelectWithLabel
        id="statistics-dropdown"
        name="statistics-dropdown"
        label="Select a statistic"
        value={selectedStat || ''}
        onChange={changeSelectedStat}
      >
        <option disabled selected={!selectedStat}>
          Select a statistic
        </option>
        {
          ["mean", "median", "stddev"].map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
      </SelectWithLabel>

      <br />
      <div className={styles.buttons}>
        <Button
          onClick={report}
          disabled={!hasDecisions}
        >
          Save Report
        </Button>
      </div>

      <br></br>
    </div>
  );
};

export default DecisionTablePage;
