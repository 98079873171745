import {
  Toaster,
  Toast,
  ToastTitle,
  ToastBody,
  useId,
  useToastController,
  ToastFooter,
  Button,
} from "@fluentui/react-components";
import React from "react";
import { generateId } from "../../helpers/generate-id";
import { IToastAction, ToastTypeEnum } from "../../interfaces/dispatch-toast";

const ToastHandler: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const toasterId = useId("error-toaster");
  const { dispatchToast, dismissToast } = useToastController(toasterId);

  const notify = (params: { type: ToastTypeEnum; message: string; action?: IToastAction }) => {
    const { message, type = "error", action } = params;

    const title = type.charAt(0).toUpperCase() + type.slice(1);
    const toastId = generateId(20);

    dispatchToast(
      <Toast>
        <ToastTitle>{title}</ToastTitle>
        <ToastBody>{message}</ToastBody>
        {action && (
          <ToastFooter>
            <Button
              onClick={() => {
                action.onClick();
                dismissToast(toastId);
              }}
            >
              {action.label}
            </Button>
          </ToastFooter>
        )}
      </Toast>,
      { intent: type, toastId }
    );
  };

  React.useEffect(() => {
    const toasterEventHandler = (
      event: CustomEvent<{ type: ToastTypeEnum; message: string; action?: IToastAction }>
    ) => {
      notify({
        type: event.detail.type,
        message: event.detail.message,
        action: event.detail.action,
      });
    };

    document.addEventListener("toasterEvent", toasterEventHandler);

    return () => {
      document.removeEventListener("toasterEvent", toasterEventHandler);
    };
  }, []);

  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" timeout={3000} />
      {children}
    </>
  );
};

export default ToastHandler;
