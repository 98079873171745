import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { removeDecision } from "./variables-slice";

interface simulationState {
  seedCell?: string | null;
  numberOfTrialsCell?: string | null;
  runDecisionTable: boolean;
  runFastSimulatios?: boolean;
}

const initialState: simulationState = {
  seedCell: undefined,
  numberOfTrialsCell: undefined,
  runDecisionTable: false,
  runFastSimulatios: false,
};

interface ISetSimulationPayload {
  seedCell?: string | null;
  numberOfTrialsCell?: string | null;
}

const simulationParamsSlice = createSlice({
  name: "simulationParams",
  initialState,
  reducers: {
    setParams(state, action: PayloadAction<ISetSimulationPayload>) {
      if (action.payload.seedCell !== undefined) {
        state.seedCell = action.payload.seedCell;
      }
      if (action.payload.numberOfTrialsCell !== undefined) {
        state.numberOfTrialsCell = action.payload.numberOfTrialsCell;
      }
    },
    setRunDecisionTable(state, action: PayloadAction<boolean>) {
      state.runDecisionTable = action.payload;

      if (state.runDecisionTable) {
        state.runFastSimulatios = true;
      }
    },
    setFastSimulation(state, action: PayloadAction<boolean>) {
      state.runFastSimulatios = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(removeDecision, (state) => {
      state.runDecisionTable = false;
    });
  }
});

export const simulationParamsActions = simulationParamsSlice.actions;

export default simulationParamsSlice.reducer;
