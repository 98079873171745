import React from "react";
import {
  Button,
  Card,
  Body1,
  CardHeader,
  CardFooter,
  Label,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import InputWithLabel from "../InputWithLabel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { IDecision, setDecisionProperties } from "../../redux/slices/variables-slice";
import {
  clearBoundCell,
  setDecisionAtCell,
  tryCatch,
  getCellsAddressRange,
  validateActiveSheet,
} from "../../taskpane";
import { useFormik } from "formik";
import * as Yup from "yup";
import { excelCellRegex } from "../../constants/regex";
import { normalizeExcelCell } from "../../helpers/normalize-cell";
import { DecisionParamsEnum, decisionParamsDisplayName } from "../../enums/decisions";
import { DecisionParamsImpl } from "../../taskpane";
import useCellUniqueness from "../../hooks/useCellUniqueness";
import { useCardStyles } from "../styles";
import usePreventNavigation from "../../hooks/usePreventNavigation";
import ToggleCollapsedButton from "../ToggleCollapsedButton";
import DeleteButton from "../DeleteButton";
import { getCellsUsedByDecision } from "../../helpers/cells-used";
import isEmpty from "lodash/isEmpty";
import FocusErrorOnSubmit from "../FocusErrorOnSubmit";

interface IDecisionCardProps {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

const ExpandedDecisionCard: React.FC<IDecisionCardProps> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();
  const dispatch = useAppDispatch();

  const storeDecision = useAppSelector((state) =>
    state.variables.decisions.find((decision) => decision.id === id)
  );

  const { 
    checkForecastCellsUniqueness,
    checkAssumptionCellsUniqueness,
    checkAssumptionDistributionParametersUniqueness,
    checkDecisionCellsUniqueness,
    checkDecisionParametersUniqueness,
    checkCorrelationUniqueness,
  } = useCellUniqueness();

  const checkFormUniqueCellValue = (value: string) => {
    const allFilledValues = getCellsUsedByDecision(form.values as IDecision);
    const nonUniqueValues = allFilledValues.filter(
      (field) => allFilledValues.indexOf(field) !== allFilledValues.lastIndexOf(field)
    );

    return !nonUniqueValues.map(normalizeExcelCell).includes(normalizeExcelCell(value));
  };

  const form = useFormik({
    initialValues: {
      cell: storeDecision.cell || "",
      decisionParams: storeDecision.decisionParams || [
        { name: DecisionParamsEnum.MINIMUM, cell: "" },
        { name: DecisionParamsEnum.MAXIMUM, cell: "" },
        { name: DecisionParamsEnum.STEP, cell: "" },
      ],
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      cell: Yup.string()
        .matches(excelCellRegex, "Invalid Excel cell format")
        .test("unique", "This cell is already used", checkFormUniqueCellValue)
        .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
        .test("unique", "This cell is already used for a forecast", checkForecastCellsUniqueness())
        .test("unique", "This cell is already used for an assumption", checkAssumptionCellsUniqueness())
        .test("unique", "This cell is already used for a distribution parameters", checkAssumptionDistributionParametersUniqueness())
        .test("unique", "This cell is already used for another decision", checkDecisionCellsUniqueness(id))
        .test("unique", "This cell is already used for another decision parameter", checkDecisionParametersUniqueness(id))
        .required("Required"),
      decisionParams: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          cell: Yup.string()
            .matches(excelCellRegex, "Invalid Excel cell format")
            .test("unique", "This cell is already used", checkFormUniqueCellValue)
            .test("unique", "This cell is already used for a forecast", checkForecastCellsUniqueness())
            .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
            .test("unique", "This cell is already used for an assumption", checkAssumptionCellsUniqueness())
            .test("unique", "This cell is already used for a distribution parameters", checkAssumptionDistributionParametersUniqueness())
            .test("unique", "This cell is already used for another decision", checkDecisionCellsUniqueness(id))
            .test("unique", "This cell is already used for another decision parameter", checkDecisionParametersUniqueness(id))
            .required("Required"),
        })
      ),
    }),
    onSubmit: (values) => {
      tryCatch(async () => {
        await validateActiveSheet();

        if (storeDecision.cell) await clearBoundCell(storeDecision.cell);
        for (const param of storeDecision.decisionParams || []) {
          if (!!param.cell && checkDecisionParametersUniqueness(storeDecision.id)(param.cell)) {
            await clearBoundCell(param.cell);
          }
        }

        console.log(values.decisionParams);

        setDecisionAtCell(
          id,
          normalizeExcelCell(values.cell), 
          values.decisionParams.map(
            (p) => new DecisionParamsImpl(decisionParamsDisplayName[p.name], p.cell)
          )
        );

        dispatch(
          setDecisionProperties({
            id,
            cell: values.cell,
            decisionParams: values.decisionParams.map((decisionParam) => ({
              name: decisionParam.name,
              cell: decisionParam.cell,
            })),
          })
        );
        form.resetForm({ values });
      });
    },
  });

  usePreventNavigation(form.dirty);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusErrorOnSubmit form={form} />
      <Card>
        <CardHeader
          header={
            <Body1 className={styles.header}>
              <b>Decision</b>
              <div className={styles.cardActions}>
                <ToggleCollapsedButton
                  id={id}
                  disabled={form.dirty || isEmpty(getCellsUsedByDecision(form.values as IDecision))}
                />
                <DeleteButton disabled={disabled} onDelete={onDelete}>
                  Remove Decision
                </DeleteButton>
              </div>
            </Body1>
          }
        />
        <Body1>
        <div className={styles.flex_even}>
            <InputWithLabel
              id={`${id}-cell`}
              name="cell"
              label="Cell*"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.cell}
              disabled={disabled}
              error={form.touched.cell && form.errors.cell}
              buttonAction={() => {
                tryCatch(async () => {
                  const range = await getCellsAddressRange();
                  form.setFieldValue("cell", range, true);
                });
              }}
              aria-required
              required
            /></div>
          <Label style={{ marginTop: "1rem", display: "block" }}>Decision parameters</Label>
          <div className={styles.flex_even}>
            {form.values.decisionParams.map((param, index) => (
              <InputWithLabel
                key={param.name}
                id={`${id}-decision-${param.name}`}
                name={`decisionParams[${index}].cell`}
                label={decisionParamsDisplayName[param.name] + '*'}
                onChange={form.handleChange}
                disabled={disabled}
                onBlur={form.handleBlur}
                value={form.values.decisionParams[index].cell + ""}
                error={
                  (form.touched.decisionParams?.[index]?.cell &&
                    (form.errors?.decisionParams?.[index] as any)?.cell) ||
                  undefined
                }
                buttonAction={() => {
                  tryCatch(async () => {
                    const range = await getCellsAddressRange();
                    form.setFieldValue(`decisionParams[${index}].cell`, range, true);
                  });
                }}
                aria-required
                required
              />
            ))}
          </div>
        </Body1>
        <CardFooter>
          <Button icon={<SaveRegular fontSize={16} />} type="submit">
            Save
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default ExpandedDecisionCard;
