import { makeStyles, tokens } from '@fluentui/react-components';
import React, { FC, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';

interface Props {
  icon: ReactNode,
  onClick: () => void;
  disabled?: boolean;
  selected: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    padding: '0 20px',
    height: '45px',
    marginTop: '0px',

    display: 'inline-flex',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,

    backgroundColor: 'transparent',
    margin: '0',
    textAlign: 'inherit',
    font: 'inherit',
    borderRadius: 0,
    appearance: 'none',

    border:'none',
    color: tokens.colorNeutralForeground2,

    overflow: 'hidden',
    position: 'relative',
    userSelect: 'none',
    fontSize: tokens.fontSizeBase300,
    boxSizing: 'border-box',

    background: tokens.colorNeutralBackground2Hover,
    borderTop: `1px solid ${tokens.colorNeutralStroke1Hover}`,
    borderRight: `1px solid ${tokens.colorNeutralStroke1Hover}`,
    borderBottom: `1px solid ${tokens.colorNeutralStroke1Hover}`,
    ':hover': {
      cursor: 'pointer',
      background: tokens.colorNeutralBackground1Hover,
    },
  },
  selected: {
    borderBottom: `1px solid transparent`,
    fontWeight: tokens.fontWeightSemibold,
    background: 'transparent',
    color: tokens.colorBrandStroke1,
    '& span': {
      color: tokens.colorNeutralForeground1Hover,
    },
    ':hover': {
      background: 'transparent',
    }
  },
  disabled: {
    color: tokens.colorNeutralForegroundDisabled,
    '& span': {
      color: tokens.colorNeutralForegroundDisabled,
    },
    ':hover': {
      cursor: 'not-allowed',
      background: 'unset',
    }
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',

    display: 'inline-flex',
    alignItems: 'center',
    gap: '10px',
  },
  hidden: {
    visibility: 'hidden',
    fontWeight: tokens.fontWeightSemibold,
  }
})

const Tab: FC<PropsWithChildren<Props>> = ({
  icon,
  selected,
  disabled,
  children,
  onClick,
})  => {
  const styles = useStyles();
  const ref = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (!selected) {
      return;
    }

    ref.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest'
    });
  }, [selected]);

  return (
    <button
      role="tab"
      ref={ref}
      type="button"
      aria-selected={selected}
      className={`
        ${styles.wrapper}
        ${selected && styles.selected}
        ${disabled && styles.disabled}
      `}
      onClick={() => !disabled && onClick()}
    >
      <div className={styles.content}>
        {icon}
        <span>{children}</span>
      </div>

      <div className={styles.hidden}>
        {icon}
        <span>{children}</span>
      </div>
    </button>
  );
}

export default Tab;