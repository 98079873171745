import { useAppSelector } from "../redux/hooks";
import { SimulationStateEnum } from "../redux/slices/simulate-slice";

const useActionsDisabled = () => {
  return useAppSelector(state => [
    SimulationStateEnum.RUNNING,
    SimulationStateEnum.PAUSED,
    SimulationStateEnum.RUNNING_FAST,
    SimulationStateEnum.CANCELING_FAST
  ].includes(state.simulate.state));
}

export default useActionsDisabled;