import isEmpty from 'lodash/isEmpty';
import { IAssumption, IDecision } from '../redux/slices/variables-slice';
import { expandCellRange } from './cell-range';

export function getCellsUsedByAssumption(assumption: IAssumption): string[] {
  let cellsUsed = [];

  if (assumption.cell) {
    cellsUsed.push(assumption.cell);
  }

  if (assumption.distributionParams) {
    cellsUsed = cellsUsed.concat(assumption.distributionParams.map(({ cell }) => cell));
  }
  
  return cellsUsed
    .filter(cell => !isEmpty(cell))
    .flatMap(expandCellRange);
}

export function getCellsUsedByDecision(decision: IDecision): string[] {
  let cellsUsed = [];

  if (decision.cell) {
    cellsUsed.push(decision.cell);
  }

  if (decision.decisionParams) {
    cellsUsed = cellsUsed.concat(decision.decisionParams.map(({ cell }) => cell));
  }
  
  return cellsUsed
    .filter(cell => !isEmpty(cell))
    .flatMap(expandCellRange);
}