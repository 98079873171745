import { makeStyles } from "@fluentui/react-components";

export const usePageStyles = makeStyles({
  root: {
    marginTop: '20px',

    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  flex_even: {
    display: "flex",
    gap: "1rem",
    "& > *": {
      flex: 1,
    },
  },
  submitButton: {
    marginTop: "1rem",
  },
  newButtonSticky: {
    position: "sticky",
    top: "44px",
    zIndex: 1,
    background: "white",
  },
  newButtonContainer: {
    position: "relative",
    width: "100%",
    left: "-16px",
    padding: "16px",
    background: "white",
    zIndex: 1,
    height: "100%",
    filter: "drop-shadow(0 6px 2px #fff)",
  },
  newButton: {
    width: "100%",
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
});
