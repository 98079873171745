import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ToastHandler from "./components/ToastHandler";
import { reset as resetRunningSimulation} from "./simulation/actions";

/* global Office */
const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  Office.addin.onVisibilityModeChanged(resetRunningSimulation);

  root?.render(
    <FluentProvider style={{ height: '100%' }} theme={webLightTheme}>
      <React.StrictMode>
        <ToastHandler>
          <HashRouter>
            <Provider store={store}>
              <App />
            </Provider>
          </HashRouter>
        </ToastHandler>
      </React.StrictMode>
    </FluentProvider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    resetRunningSimulation();
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}
