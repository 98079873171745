import React, { FC } from "react";
import { clearBoundCell, tryCatch } from "../../taskpane";
import { removeDecision } from "../../redux/slices/variables-slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useCellUniqueness from "../../hooks/useCellUniqueness";
import ExpandedDecisionCard from "./ExpandedDecisionCard";
import CollapsedDecisionCard from "./CollapsedDecisionCard";

interface Props {
  id: string;
  disabled?: boolean;
}

const DecisionCard: FC<Props> = ({ id, disabled }) => {
  const dispatch = useAppDispatch();
  const { checkDecisionParametersUniqueness } = useCellUniqueness();

  const collapsed = useAppSelector((state) =>
    state.collapsed[id]
  );

  const storeDecision = useAppSelector((state) =>
    state.variables.decisions.find((decision) => decision.id === id)
  );

  const handleRemoveDecision = () => {
    tryCatch(() => {
      if (storeDecision.cell) clearBoundCell(storeDecision.cell);
      storeDecision.decisionParams?.forEach((param) => {
        if (!!param.cell && checkDecisionParametersUniqueness(storeDecision.id)(param.cell)) {
          clearBoundCell(param.cell);
        }
      });
      dispatch(removeDecision({ id }));
    });
  };

  return (
    <>
      {!collapsed && <ExpandedDecisionCard id={id} disabled={disabled} onDelete={handleRemoveDecision} />}
      {collapsed && <CollapsedDecisionCard id={id} disabled={disabled} onDelete={handleRemoveDecision} />}
    </>
  );
}

export default DecisionCard;