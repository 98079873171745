import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { simulateActions, SimulationStateEnum } from "../redux/slices/simulate-slice";
import { store } from "../redux/store";
import { loadStats, reset, runFastSimulationStep, runSimulationStep } from "../simulation/actions";

const useSimulation = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const simulation = useAppSelector((state) => state.simulate);

  useEffect(() => {
    let isRunning = [SimulationStateEnum.RUNNING, SimulationStateEnum.RUNNING_FAST].includes(simulation.state);
    history.block(!isRunning);

    async function run() {
      // It takes a bit until reactJS is able to get the latest state
      // so we grab it directly from the store
      const updatedState = store.getState().simulate.state;
      if (updatedState === SimulationStateEnum.RUNNING) {
        if (await runSimulationStep()) {
          run();
        }
      } else {
        loadStats();
      }
    }

    async function runFast() {
      const updatedState = store.getState().simulate.state;
      if (updatedState === SimulationStateEnum.RUNNING_FAST) {
        if (await runFastSimulationStep()) {
          runFast();
        }
      } else if (updatedState === SimulationStateEnum.CANCELING_FAST) {
        reset();
        dispatch(simulateActions.setState(SimulationStateEnum.NOT_STARTED));
      }
    }
    
    if (simulation.state === SimulationStateEnum.RUNNING) {
      run();
    } else if (simulation.state === SimulationStateEnum.RUNNING_FAST) {
      runFast();
    }
  }, [simulation.state]);

  const startFast = useCallback(async () => {
    dispatch(simulateActions.reset());
    dispatch(simulateActions.setState(SimulationStateEnum.RUNNING_FAST));
  }, []);

  const startOrResume = useCallback(() => {
    dispatch(simulateActions.setState(SimulationStateEnum.RUNNING));
  }, []);

  const startNew = useCallback(() => {
    dispatch(simulateActions.reset());
    dispatch(simulateActions.setState(SimulationStateEnum.RUNNING));
  }, []);

  const pause = useCallback(() => {
    dispatch(simulateActions.setState(SimulationStateEnum.PAUSED));
  }, []);

  const nextStep = useCallback(async () => {
    const oldState = simulation.state;
    try {
      await runSimulationStep();
      dispatch(simulateActions.setState(SimulationStateEnum.PAUSED));
      loadStats();
    } catch {
      dispatch(simulateActions.setState(oldState));
    }
  }, []);

  const cancelFast = useCallback(() => {
    dispatch(simulateActions.setState(SimulationStateEnum.CANCELING_FAST));
  }, []);

  return {
    simulation,

    startFast,
    startOrResume,
    startNew,
    nextStep,
    pause,
    reset,
    cancelFast,
  };
};

export default useSimulation;