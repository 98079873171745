import { makeStyles, tokens } from "@fluentui/react-components";

export const useCardStyles = makeStyles({
  form: {
    display: 'flex',
    gap: 'var(--fui-Card--size)',
    flexDirection: 'column'
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  flex_even: {
    display: "flex",
    gap: "1rem",
    "& > *": {
      flex: 1,
    },
  },
  error: {
    color: tokens.colorStatusDangerForeground3,
  },
  cardActions: {
    display: 'inline-flex',
    gap: '10px'
  },
  rowBody: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem'
  },
});