import { makeStyles, typographyStyles } from "@fluentui/react-components";

const useSimulateStyles = makeStyles({
  root: {
    marginTop: '2rem',
  },
  inputForm: {
    marginBottom: "2rem",
  },
  inputs: {
    display: "flex",
    gap: "1rem",
    flexDirection: "row",
    marginBottom: "1rem",
  },
  buttons: {
    display: "flex",
    gap: "1rem",
  },
  buttonIcon: {
    marginRight: "10px",
    fontSize: "2rem",
  },
  runDecisionParams: {
    marginBottom: "1rem",
  },
  simulationStatus: {
    ...typographyStyles.body2,
    marginTop: '2rem',
    display: 'block'
  }
});

export default useSimulateStyles;