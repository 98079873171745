import React, { useEffect } from "react";
import isString from 'lodash/isString';
import {
  Button,
  Card,
  Body1,
  CardHeader,
  CardFooter,
  Label,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import SelectWithLabel from "../SelectWithLabel";
import InputWithLabel from "../InputWithLabel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { IAssumption, setAssumptionProperties } from "../../redux/slices/variables-slice";
import {
  clearBoundCell,
  setAssumptionAtCell,
  DistributionParams,
  DistributionParamsImpl,
  tryCatch,
  getCellsAddressRange,
  updateAssociatedCorrelations,
  validateActiveSheet,
} from "../../taskpane";
import {
  DistributionParamsEnum,
  DistributionsEnum,
  distributionParams,
  distributionParamsDisplayName,
} from "../../enums/distributions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { excelCellRegex, excelRangeRegex } from "../../constants/regex";
import useCellUniqueness from "../../hooks/useCellUniqueness";
import { areRangeCellsInSameColumn, areRangeCellsInSameRow, expandCellRange } from "../../helpers/cell-range";
import { allCustomDistributionRangesAreEqual } from "../../validations/assuption-range-validations";
import { getCellsUsedByAssumption } from "../../helpers/cells-used";
import { useCardStyles } from "../styles";
import DeleteButton from "../DeleteButton";
import ToggleCollapsedButton from "../ToggleCollapsedButton";
import isEmpty from "lodash/isEmpty";
import usePreventNavigation from "../../hooks/usePreventNavigation";
import { normalizeExcelCell } from "../../helpers/normalize-cell";
import FocusErrorOnSubmit from "../FocusErrorOnSubmit";

interface IAssumptionCardProps {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

function requiredMark(name: string) {
  if (name === DistributionParamsEnum.PROBABILITIES_RANGE) {
    return ''
  }
  return '*'
}

const AssumptionCard: React.FC<IAssumptionCardProps> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();
  const dispatch = useAppDispatch();

  const storeAssumption = useAppSelector((state) =>
    state.variables.assumptions.find((assumption) => assumption.id === id)
  );

  const { 
    checkForecastCellsUniqueness,
    checkAssumptionCellsUniqueness,
    checkAssumptionDistributionParametersUniqueness,
    checkDecisionCellsUniqueness,
    checkDecisionParametersUniqueness,
    checkUniqueness,
    checkCorrelationUniqueness
  } = useCellUniqueness();

  const checkFormUniqueCellValue = (value: string) => {
    const allFilledValues = getCellsUsedByAssumption(form.values as IAssumption);

    const nonUniqueValues = allFilledValues.filter(
      (field) => allFilledValues.indexOf(field) !== allFilledValues.lastIndexOf(field)
    );

    const valueCells = expandCellRange(value);
    return !valueCells.find(cell => nonUniqueValues.includes(cell));
  };

  const form = useFormik({
    initialValues: {
      cell: storeAssumption.cell || "",
      distribution: storeAssumption.distribution || DistributionsEnum.NORMAL,
      distributionParams: storeAssumption.distributionParams || [
        { name: DistributionParamsEnum.MEAN, cell: "" },
        { name: DistributionParamsEnum.STANDARD_DEVIATION, cell: "" },
      ],
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      cell: Yup.string()
        .matches(excelCellRegex, "Invalid Excel cell format")
        .test("unique", "This cell is already used", checkFormUniqueCellValue)
        .test("unique", "This cell is already used for a forecast", checkForecastCellsUniqueness())
        .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
        .test("unique", "This cell is already used for another assumption", checkAssumptionCellsUniqueness(id))
        .test("unique", "This cell is already used for another distribution parameter", checkAssumptionDistributionParametersUniqueness(id))
        .test("unique", "This cell is already used for a decision", checkDecisionCellsUniqueness())
        .test("unique", "This cell is already used for a decision parameter", checkDecisionParametersUniqueness())
        .required("Required"),
      distribution: Yup.string().required("Required"),
      distributionParams: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required("Required"),
            cell: Yup.string(),
          })
        )
        .when("distribution", {
          is: DistributionsEnum.CUSTOM,
          then: (schema) =>
            schema
              .of(
                Yup.object().shape({
                  name: Yup.string().required("Required"),
                  cell: Yup.string()
                    .matches(excelRangeRegex, "Invalid Excel range cell format")
                    .test("unique", "One of the cells is already used", checkFormUniqueCellValue)
                    .test("unique", "One of the cells is already used for a forecast", checkForecastCellsUniqueness())
                    .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
                    .test("unique", "One of the cells is already used for another assumption", checkAssumptionCellsUniqueness(id))
                    .test("unique", "One of the cells is already used for another distribution parameter", checkAssumptionDistributionParametersUniqueness(id))
                    .test("unique", "One of the cells is already used for a decision", checkDecisionCellsUniqueness())
                    .test("unique", "One of the cells is already used for a decision parameter", checkDecisionParametersUniqueness())
                    .test('required', "The range of values is required", (_value, ctx) => {
                      const distributionParam = ctx.parent as IAssumption['distributionParams'][0];

                      if (distributionParam.name !== DistributionParamsEnum.VALUES_RANGE) {
                        return true;
                      }

                      return !isEmpty(distributionParam.cell)
                    })
                    .test("string", "The specifed range can have the following type 1xN", (range) => (range
                      ? (areRangeCellsInSameRow(range) || areRangeCellsInSameColumn(range))
                      : true))
                })
              ).test('unique', allCustomDistributionRangesAreEqual),
          otherwise: (schema) =>
            schema.of(
              Yup.object().shape({
                name: Yup.string().required("Required"),
                cell: Yup.string()
                  .matches(excelCellRegex, "Invalid Excel cell format")
                  .test("unique", "This cell is already used", checkFormUniqueCellValue)
                  .test("unique", "This cell is already used for a forecast", checkForecastCellsUniqueness())
                  .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
                  .test("unique", "This cell is already used for another assumption", checkAssumptionCellsUniqueness(id))
                  .test("unique", "This cell is already used for another distribution parameter", checkAssumptionDistributionParametersUniqueness(id))
                  .test("unique", "This cell is already used for a decision", checkDecisionCellsUniqueness())
                  .test("unique", "This cell is already used for a decision parameter", checkDecisionParametersUniqueness())
                  .required("Required"),
              })
            ),
        }),
    }),
    onSubmit: (values) => {
      tryCatch(async () => {
        await validateActiveSheet();

        for (const cell of getCellsUsedByAssumption(storeAssumption)) {
          if (checkUniqueness(storeAssumption.id)(cell)) {
            await clearBoundCell(cell);
          }
        }

        console.log(values.distributionParams);
        await updateAssociatedCorrelations(storeAssumption.cell, values.cell);
        await defineAssumption(
          values.cell,
          values.distribution,
          values.distributionParams.map(
            (p) => new DistributionParamsImpl(distributionParamsDisplayName[p.name], p.cell)
          )
        );
        dispatch(
          setAssumptionProperties({
            id,
            cell: values.cell,
            distribution: values.distribution,
            distributionParams: values.distributionParams.map((distributionParam) => ({
              name: distributionParam.name,
              cell: distributionParam.cell,
            })),
          })
        );

        form.resetForm({ values });
      });
    },
  });

  useEffect(() => {
    const distribution = form.values.distribution;

    if (distributionParams[distribution]) {
      form
        .setFieldValue(
          "distributionParams",
          distributionParams[distribution].map((param) => ({
            name: param,
            cell: form.values.distributionParams.find((p) => p.name === param)?.cell || "",
          }))
        )
        .then(() => {
          form.setFieldTouched("distributionParams", false);
        });
    }
  }, [form.values.distribution]);

  const defineAssumption = async (
    cell: string,
    distribution: DistributionsEnum,
    distributionParams: DistributionParams[]
  ) => {
    await tryCatch(() => setAssumptionAtCell(id, normalizeExcelCell(cell), distribution, distributionParams));
  };

  const getCellFromSelection = async () => {
    tryCatch(async () => {
      const range = await getCellsAddressRange();
      form.setFieldValue("cell", range, true);
    });
  };

  const getDistributionParamCellFromSelection = (index: number) => async () => {
    tryCatch(async () => {
      const range = await getCellsAddressRange();
      form.setFieldValue(`distributionParams[${index}].cell`, range, true);
    });
  };

  usePreventNavigation(form.dirty);

  return (
    <form onSubmit={form.handleSubmit} className={styles.form}>
      <FocusErrorOnSubmit form={form} />
      <Card>
        <CardHeader
          header={
            <Body1 className={styles.header}>
              <b>Assumption</b>

              <div className={styles.cardActions}>
                <ToggleCollapsedButton id={id} disabled={form.dirty || isEmpty(getCellsUsedByAssumption(form.values as IAssumption))}/>
                <DeleteButton disabled={disabled} onDelete={onDelete}>
                  Remove Assumption
                </DeleteButton>
              </div>
            </Body1>
          }
        />
        <Body1>
          <div className={styles.flex_even}>
            <InputWithLabel
              id={`${id}-cell`}
              name="cell"
              label="Cell*"
              onChange={form.handleChange}
              disabled={disabled}
              buttonAction={getCellFromSelection}
              onBlur={form.handleBlur}
              value={form.values.cell}
              error={form.touched.cell && form.errors.cell}
              aria-required
              required
            />
            <SelectWithLabel
              id={`${id}-select`}
              name="distribution"
              label="Distribution*"
              disabled={disabled}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.distribution}
              aria-required
              required
            >
              {Object.values(DistributionsEnum).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </SelectWithLabel>
          </div>

          <Label style={{ marginTop: "1rem", display: "block" }}>Distribution parameters</Label>
          <div className={styles.flex_even}>
            {form.values.distributionParams.map((param, index) => (
              <InputWithLabel
                key={param.name}
                id={`${id}-distribution-${param.name}`}
                name={`distributionParams[${index}].cell`}
                label={distributionParamsDisplayName[param.name] + requiredMark(param.name)}
                disabled={disabled}
                onChange={form.handleChange}
                buttonAction={getDistributionParamCellFromSelection(index)}
                onBlur={form.handleBlur}
                value={form.values.distributionParams[index].cell + ""}
                error={
                  (form.touched.distributionParams?.[index]?.cell &&
                    (form.errors?.distributionParams?.[index] as any)?.cell) ||
                  undefined
                }
                aria-required={!!requiredMark(param.name)}
                required={!!requiredMark(param.name)}
              />
            ))}
          </div>
          {form.touched?.distributionParams && isString(form.errors?.distributionParams) && (
            <div className={styles.error}>{form.errors?.distributionParams as string}</div>
          )}
        </Body1>
        <CardFooter>
          <Button icon={<SaveRegular fontSize={16} />} type="submit">
            Save
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default AssumptionCard;

