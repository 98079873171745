import { Dropdown, Label, makeStyles, tokens, useId } from "@fluentui/react-components";
import type { DropdownProps } from "@fluentui/react-components";
import React from "react";

export interface IDropdownWithLabelProps extends DropdownProps {
  id: string;
  label: string;
  name: string;
  error?: string | null;
}

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
  error: {
    color: tokens.colorStatusDangerForeground3,
  },
});

const DropdownWithLabel: React.FC<IDropdownWithLabelProps> = ({ id, label, name, error, ...props }) => {
  const inputId = useId(id);
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Label htmlFor={inputId} size={props.size} disabled={props.disabled}>
        {label}
      </Label>
      <Dropdown
        id={inputId}
        name={name}
        {...props}
        aria-describedby={inputId + 'error'}
      />
      {error && (
        <div className={styles.error}>
          <span id={inputId + 'error'}>{error}</span>
        </div>
      )}
    </div>
  );
};

export default DropdownWithLabel;
