import { Label, Select, makeStyles, useId } from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";
import React from "react";

export interface SelectWithLabelProps extends SelectProps {
  id: string;
  label: string;
  name: string;
}

const useStyles = makeStyles({
  root: {
    // Stack the label above the field
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    gap: "2px",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
  },
});

const SelectWithLabel: React.FC<SelectWithLabelProps> = ({ id, label, name, ...props }) => {
  const inputId = useId(id);
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Label htmlFor={inputId} size={props.size} disabled={props.disabled}>
        {label}
      </Label>
      <Select id={inputId} name={name} {...props} />
    </div>
  );
};

export default SelectWithLabel;
