/* global Office */
import * as React from "react";
import { Tab, TabList } from "@fluentui/react-components";
import { useLocation, useHistory } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "../Layout";
import { MainRoutesEnum, DecisionTabPages, AssumptionTabPages } from "../../routes/routes";
import { SimulationPesistence } from "../../simulation/persistence";
import { useAppDispatch } from "../../redux/hooks";
import { loadAssumptions, loadForecasts, loadDecisions, loadCorrelations } from "../../redux/slices/variables-slice";
import { DistributionsEnum, distributionParams as distributionParamsMap } from "../../enums/distributions";
import { DecisionParamsEnum } from "../../enums/decisions";
import { CorrelationParamsEnum } from "../../enums/correlations";
import DecisionPage from "../../pages/DecisionPage";
import CorrelationPage from "../../pages/CorrelationPage";
import AssumptionPage from "../../pages/AssumptionPage";
import ForecastPage from "../../pages/ForecastPage";
import SimulatePage from "../../pages/SimulatePage";
import AnalyzePage from "../../pages/AnalyzePage";
import DecisionTablePage from "../../pages/DecisionTablePage";
import PersistCollapsedState from "../PersistCollapsedState";
import { collapsedActions } from "../../redux/slices/collapsed-slice";
import { simulationParamsActions } from "../../redux/slices/simulation-params-slice";
import { reset as resetRunningSimulation } from "../../simulation/actions";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    resetRunningSimulation();

    Office.onReady(async () => {
      const simstate = new SimulationPesistence();
      await simstate.load();
      const decisions = simstate.Decisions;
      const assumptions = simstate.Assumptions;
      const forecasts = simstate.Forecasts;
      const correlations = simstate.Correlations;
      const distributionParams = {
        numberOfTrialsCell: simstate.NumberOfTrialsCell,
        seedCell: simstate.SeedCell,
      };

      dispatch(collapsedActions.load(simstate.CollapsedState || {}));

      dispatch(
        loadAssumptions(
          assumptions.map((assumption, assumptionIndex) => {
            const assumptionParamsCells = assumptions[assumptionIndex].ProbabilityDistribution.GetParamCells();
            const assumptionParamsNames =
              distributionParamsMap[assumptions[assumptionIndex].ProbabilityDistribution.Name];
            const distributionParams = assumptionParamsCells.map((cell, cellIndex) => ({
              cell,
              name: assumptionParamsNames[cellIndex],
            }));

            return {
              id: assumption.ID,
              cell: assumption.BoundCell,
              distribution: assumption.ProbabilityDistribution.Name as DistributionsEnum,
              distributionParams,
            };
          })
        )
      );

      dispatch(
        loadDecisions(
          decisions.map((decision) => {
            return {
              id: decision.ID,
              cell: decision.BoundCell,
              decisionParams: [
                { cell: decision.MinCell, name: DecisionParamsEnum.MINIMUM },
                { cell: decision.MaxCell, name: DecisionParamsEnum.MAXIMUM },
                { cell: decision.StepCell, name: DecisionParamsEnum.STEP },
              ],
            };
          })
        )
      );

      dispatch(
        loadForecasts(
          forecasts.map((forecast) => {
            return {
              id: forecast.ID,
              cell: forecast.BoundCell,
            };
          })
        )
      );

      dispatch(
        loadCorrelations(
          correlations.map((correlation) => ({
            id: correlation.ID,
            cell: correlation.BoundCell,
            correlationParams: [
              { assumptionCell: correlation.FirstAssumptionCell, name: CorrelationParamsEnum.FIRST_ASSUMPTION },
              { assumptionCell: correlation.SecondAssumptionCell, name: CorrelationParamsEnum.SECOND_ASSUMPTION }
            ],
          }))
        )
      );

      dispatch(simulationParamsActions.setParams(distributionParams));
    });
  }, []);

  return (
    <Layout>
      <PersistCollapsedState />

      <Switch>
        <Route path={MainRoutesEnum.ASSUMPTION_TAB}>
          <TabList selectedValue={location.pathname} appearance="subtle">
            <Tab
              value={AssumptionTabPages.ASSUMPTIONS}
              onClick={() => history.push(AssumptionTabPages.ASSUMPTIONS)}
            >
              Assumptions
            </Tab>
            <Tab
              value={AssumptionTabPages.CORRELATIONS}
              onClick={() => history.push(AssumptionTabPages.CORRELATIONS)}
            >
              Correlations
            </Tab>
          </TabList>
          <Switch>
            <Route path={AssumptionTabPages.ASSUMPTIONS}>
              <AssumptionPage />
            </Route>

            <Route path={AssumptionTabPages.CORRELATIONS}>
              <CorrelationPage />
            </Route>

            <Route>
              <Redirect to={AssumptionTabPages.ASSUMPTIONS} />
            </Route>
          </Switch>
        </Route>

        <Route path={MainRoutesEnum.FORECAST_TAB}>
          <ForecastPage />
        </Route>

        <Route path={MainRoutesEnum.SIMULATE_TAB}>
          <SimulatePage />
        </Route>

        <Route path={MainRoutesEnum.ANALYZE_TAB}>
          <AnalyzePage />
        </Route>

        <Route path={MainRoutesEnum.DECISION_TAB}>
          <TabList selectedValue={location.pathname} appearance="subtle">
            <Tab
              value={DecisionTabPages.DECISIONS}
              onClick={() => history.push(DecisionTabPages.DECISIONS)}
            >
              Decisions
            </Tab>
            <Tab
              value={DecisionTabPages.DECISION_TABLES}
              onClick={() => history.push(DecisionTabPages.DECISION_TABLES)}
            >
              Decision Tables
            </Tab>
          </TabList>

          <Switch>
            <Route path={DecisionTabPages.DECISIONS}>
              <DecisionPage />
            </Route>

            <Route path={DecisionTabPages.DECISION_TABLES}>
              <DecisionTablePage />
            </Route>

            <Route>
              <Redirect to={DecisionTabPages.DECISIONS} />
            </Route>
          </Switch>
        </Route>

        <Route>
          <Redirect to={MainRoutesEnum.ASSUMPTION_TAB} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
