import React, { useMemo, useState, useEffect } from "react";
import { Button, makeStyles, SelectOnChangeData, Tooltip } from "@fluentui/react-components";
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
} from "@fluentui/react-components";
import { AlignBottomRegular, ArrowBidirectionalLeftRightFilled, CenterHorizontalRegular } from "@fluentui/react-icons";
import Histogram from "../../components/Histogram";
import MultiRangeSlider from "../../components/MultiRangeSlider";
import { useAppSelector } from "../../redux/hooks";
import { HISTOGRAM_OVERFLOW } from "../../components/Histogram/constants";
import SelectWithLabel from "../../components/SelectWithLabel";
import NumberOfBinsInput from "./NumberOfBinsInput";
import { createReportSheetFor } from "../../taskpane";
import _ from "lodash";

const useStyles = makeStyles({
  root: {
    marginTop: '2rem',
  },
  slider: {
    display: "flex",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    gap: "1rem",
  },
  binSizeWrapper: {
    display: "flex",
    justifyContent: "center",
  }
});

function formatStatsValue(value?: number) {
  if (!value) {
    return '';
  }

  if (Math.abs(value) < 1e-4) {
    return value.toExponential(4);
  }

  return value.toFixed(4);
}

function computeThresholdProbability(histogramData: number[] | null, lowerThreshold: number, upperThreshold: number) {
  if (!histogramData || histogramData.length === 0) {
    return 0;
  }

  const filteredData = histogramData.filter(v => lowerThreshold <= v && v <= upperThreshold);
  return (filteredData.length / histogramData.length) * 100;
}

const MAX_BINS = 100;

const AnalyzePage: React.FC = () => {
  const styles = useStyles();
  const simulationOutputs = useAppSelector(state => state.simulationOutput.outputs);

  const [selectedDecision, setSelectedDecision] = useState<string | undefined>(Array.from(simulationOutputs.keys())?.[0]);
  const [selectedForecast, setSelectedStat] = useState<string | undefined>(simulationOutputs.get(selectedDecision)?.keys()?.next()?.value);
  const [lowerThreshold, setLowerThreshold] = useState(0);
  const [upperThreshold, setUpperThreshold] = useState(1000);
  const [numberOfBins, setNumberOfBins] = useState(0);

  const histogramData = useMemo(
    () => simulationOutputs.get(selectedDecision)?.get(selectedForecast)?.forecast_values || [],
    [selectedDecision, selectedForecast]
  );

  const multiResizeMin = useMemo(() => {
    return _.min(histogramData) - HISTOGRAM_OVERFLOW;
  }, [selectedDecision, selectedForecast]);

  const multiResizeMax = useMemo(() => {
    return _.max(histogramData) + HISTOGRAM_OVERFLOW
  }, [selectedDecision, selectedForecast]);

  useEffect(() => {
    setNumberOfBins(Math.min(new Set(histogramData).size, MAX_BINS));
    if (histogramData.length) {
      setLowerThreshold(Number(multiResizeMin.toFixed(2)));
      setUpperThreshold(Number(multiResizeMax.toFixed(2)));
    }
    console.log('stats', simulationOutputs);
  }, [histogramData]);

  const changeSelectedForecast = (_, data: SelectOnChangeData) => {
    setSelectedStat(data.value);
  };

  const changeSelectedDecision = (_, data: SelectOnChangeData) => {
    setSelectedDecision(data.value);
  };

  const thresholdProbability = useMemo(() => {
    return computeThresholdProbability(histogramData, lowerThreshold, upperThreshold);
  }, [histogramData, lowerThreshold, upperThreshold]);

  return (
    <div className={styles.root}>
      <SelectWithLabel
        id="statistics-dropdown"
        name="statistics-dropdown"
        label="Select a forecast cell"
        onChange={changeSelectedForecast}
      >
        <option disabled selected={!selectedForecast}>Select a forecast cell</option>
        {selectedDecision &&
          Array.from(simulationOutputs.get(selectedDecision).keys()).map((key) => (
            <option key={key} value={key} selected={key === selectedForecast}>
              {key}
            </option>
          ))}
      </SelectWithLabel>

      <br />

      {Array.from(simulationOutputs.keys()).length > 1 && (
        <SelectWithLabel
          id="decision-dropdown"
          name="decision-dropdown"
          label="Select a combination of values for decisions"
          value={selectedDecision || ''}
          onChange={changeSelectedDecision}
        >
          <option disabled selected={!selectedDecision}>
            Select a combination of values for decisions
          </option>
          {Array.from(simulationOutputs.keys()).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </SelectWithLabel>
      )}

      <br />

      <Button
        onClick={() => createReportSheetFor(
          selectedDecision, selectedForecast, numberOfBins,
          lowerThreshold,
          upperThreshold,
          thresholdProbability
        )}
        disabled={!selectedForecast}
      >
        Save Report
      </Button>

      <br />


      <div className={styles.binSizeWrapper}>
        <NumberOfBinsInput
          key={selectedForecast || ''}
          disabled={!histogramData || !histogramData.length}
          value={numberOfBins}
          onChange={setNumberOfBins}
          max={Math.min(histogramData?.length, MAX_BINS)}
        />
      </div>

      <br />

      <Histogram
        numberOfBins={numberOfBins}
        data={histogramData}
        lowerThreshold={lowerThreshold}
        upperThreshold={upperThreshold}
      />

      <div className={styles.slider}>
        <MultiRangeSlider
          min={multiResizeMin || 0}
          max={multiResizeMax || 0}
          minValue={lowerThreshold || 0}
          maxValue={upperThreshold || 0}
          disabled={!histogramData.length}
          onChange={({ min, max }) => {
            setLowerThreshold(min);
            setUpperThreshold(max);
          }}
        >
          {thresholdProbability >= 0 && (
            <Tooltip content="Calculated probability" relationship="label">
              <span>{thresholdProbability.toFixed(2)}%</span>
            </Tooltip>
          )}
        </MultiRangeSlider>
      </div>

      <br />

      <Table size="small" aria-label="Table with calculated mean, standard deviation and median">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Statistic</TableHeaderCell>
            <TableHeaderCell>Data</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <TableCellLayout media={<CenterHorizontalRegular />}>Mean</TableCellLayout>
            </TableCell>
            <TableCell>{formatStatsValue(simulationOutputs.get(selectedDecision)?.get(selectedForecast)?.mean)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <TableCellLayout media={<ArrowBidirectionalLeftRightFilled />}>Standard deviation</TableCellLayout>
            </TableCell>
            <TableCell>{formatStatsValue(simulationOutputs.get(selectedDecision)?.get(selectedForecast)?.stddev)}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <TableCellLayout media={<AlignBottomRegular />}>Median</TableCellLayout>
            </TableCell>
            <TableCell>{formatStatsValue(simulationOutputs.get(selectedDecision)?.get(selectedForecast)?.median)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default AnalyzePage;
