import React, { FC, useEffect } from 'react';
import { FormikContextType, FormikValues, getIn } from "formik";
import flattenToLodashFormat from '../helpers/flattenToLodashFormat';

interface Props {
   form: FormikContextType<FormikValues>
}

const FocusErrorOnSubmit: FC<Props> = ({ form }) => {
  useEffect(() => {
    if (!form.isSubmitting) return;

    const touchedErrors = flattenToLodashFormat(form.touched);
    
    const errorNames = Object.keys(touchedErrors).reduce((prev, key) => {
      if (getIn(form.errors, key)) {
        prev.push(key);
      }
      return prev;
    }, [] as string[]);

    if (errorNames.length && typeof document !== "undefined") {
      let errorElement: HTMLElement | null;

      errorNames.forEach((errorKey) => {
        const selector = `[name="${errorKey}"]`;
        if (!errorElement) {
          errorElement = document.querySelector(selector);
          return;
        }
      });

      errorElement && errorElement.focus();
    }
  }, [form.isSubmitting, form.isValidating]);

  return null;
};

export default FocusErrorOnSubmit;