import { Body1, Card, CardHeader } from '@fluentui/react-components';
import React, { FC } from 'react';
import { useCardStyles } from '../styles';
import { useAppSelector } from '../../redux/hooks';
import DeleteButton from '../DeleteButton';
import ToggleCollapsedButton from '../ToggleCollapsedButton';
import { distributionParamsDisplayName } from '../../enums/distributions';

interface Props {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

const CollapsedAssumptionCard: FC<Props> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();

  const assumption = useAppSelector((state) =>
    state.variables.assumptions.find((assumption) => assumption.id === id)
  );

  return (
    <Card>
      <CardHeader
        header={
          <Body1 className={styles.header}>
            <span>
              <b>{assumption.cell}</b>
              {' '}
              -
              {' '}
              {assumption.distribution}
            </span>

            <div className={styles.cardActions}>
              <ToggleCollapsedButton id={id} />
              <DeleteButton disabled={disabled} onDelete={onDelete}>
                Remove Assumption
              </DeleteButton>
            </div>
          </Body1>
        }
      />
      <Body1 className={styles.rowBody}>
        {assumption.distributionParams?.map(param => (
          <div key={param.name}>
            <b>{param.cell}</b> - {distributionParamsDisplayName[param.name]}
          </div>
        ))}
      </Body1>
    </Card>
  );
}

export default CollapsedAssumptionCard;