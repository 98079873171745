import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEmpty from 'lodash/isEmpty';
import { generateId } from "../../helpers/generate-id";
import { DistributionsEnum } from "../../enums/distributions";

export interface IAssumption {
  id: string;
  cell: string;
  distribution?: DistributionsEnum;
  distributionParams?: { name: string; cell: string }[];
}

export interface IDecision {
  id: string;
  cell: string;
  decisionParams?: { name: string; cell: string }[];
}

export interface IForecast {
  id: string;
  cell: string;
}

export interface ICorrelation {
  id: string;
  cell: string;
  correlationParams?: { name: string, assumptionCell: IAssumption['cell'] }[];
}
interface VariablesState {
  assumptions: IAssumption[];
  decisions: IDecision[];
  forecasts: IForecast[];
  correlations: ICorrelation[];
}

const initialState: VariablesState = {
  assumptions: [{ id: generateId(20), cell: "", distribution: undefined, distributionParams: undefined }],
  decisions: [{ id: generateId(20), cell: "", decisionParams: undefined }],
  forecasts: [{ id: generateId(20), cell: "" }],
  correlations: [{ id: generateId(20), cell: "" }],
};

interface IRemoveVariablePayload {
  id: string;
}

const variablesSlice = createSlice({
  name: "variables",
  initialState,
  reducers: {
    addAssumption(state) {
      state.assumptions.unshift({ id: generateId(20), cell: "", distribution: undefined });
    },
    removeAssumption(state, action: PayloadAction<IRemoveVariablePayload>) {
      const toRemoveAssumption = state.assumptions.find((assumption) => assumption.id === action.payload.id);
      if (!toRemoveAssumption) {
        return;
      }

      state.assumptions = state.assumptions.filter((assumption) => assumption.id !== action.payload.id);
      // Remove associated correlations
      state.correlations = state.correlations.filter(({ correlationParams }) => !correlationParams?.find(param => param.assumptionCell === toRemoveAssumption.cell));
    },
    setAssumptionProperties(state, action: PayloadAction<IAssumption>) {
      const index = state.assumptions.findIndex((assumption) => assumption.id === action.payload.id);
      
      // Update associated correlations
      const oldAssuptionCell = state.assumptions[index].cell;
      if (oldAssuptionCell && action.payload.cell) {
        state.correlations
          .filter(({ correlationParams }) => correlationParams?.some(param => param.assumptionCell === oldAssuptionCell))
          .forEach(correlation => correlation.correlationParams
            ?.filter(param => param.assumptionCell === oldAssuptionCell)
            .forEach(param => {
              param.assumptionCell = action.payload.cell;
            }));
      }

      state.assumptions[index] = action.payload;
    },
    loadAssumptions(state, action: PayloadAction<IAssumption[]>) {
      state.assumptions = action.payload.map((assumption) => ({ ...assumption }));
    },

    addDecision(state) {
      state.decisions.unshift({ id: generateId(20), cell: "" });
    },
    removeDecision(state, action: PayloadAction<IRemoveVariablePayload>) {
      state.decisions = state.decisions.filter((decision) => decision.id !== action.payload.id);
    },
    setDecisionProperties(state, action: PayloadAction<IDecision>) {
      const index = state.decisions.findIndex((decision) => decision.id === action.payload.id);
      state.decisions[index] = action.payload;
    },
    loadDecisions(state, action: PayloadAction<IDecision[]>) {
      state.decisions = action.payload.map((decision) => ({ ...decision }));
    },

    addForecast(state) {
      state.forecasts.unshift({ id: generateId(20), cell: "" });
    },
    removeForecast(state, action: PayloadAction<IRemoveVariablePayload>) {
      state.forecasts = state.forecasts.filter((forecast) => forecast.id !== action.payload.id);
    },
    setForecastProperties(state, action: PayloadAction<IForecast>) {
      const index = state.forecasts.findIndex((forecast) => forecast.id === action.payload.id);
      state.forecasts[index] = action.payload;
    },
    loadForecasts(state, action: PayloadAction<IForecast[]>) {
      state.forecasts = action.payload.map((forecast) => ({ ...forecast }));
    },

    addCorrelation(state) {
      state.correlations.unshift({ id: generateId(20), cell: "" });
    },
    removeCorrelation(state, action: PayloadAction<IRemoveVariablePayload>) {
      state.correlations = state.correlations.filter(correlation => correlation.id !== action.payload.id);
    },
    setCorrelationProperties(state, action: PayloadAction<ICorrelation>) {
      const index = state.correlations.findIndex(correlation => correlation.id === action.payload.id);
      state.correlations[index] = action.payload;
    },
    loadCorrelations(state, action: PayloadAction<ICorrelation[]>) {
      state.correlations = action.payload.map(correlation => ({ ...correlation }));
    },
  },
});

export const {
  addAssumption,
  removeAssumption,
  setAssumptionProperties,
  loadAssumptions,
  addDecision,
  removeDecision,
  setDecisionProperties,
  loadDecisions,
  addForecast,
  removeForecast,
  setForecastProperties,
  loadForecasts,
  addCorrelation,
  removeCorrelation,
  setCorrelationProperties,
  loadCorrelations,
} = variablesSlice.actions;

export default variablesSlice.reducer;
