import throttle from "lodash/throttle";
import { store } from "../redux/store";
import { simulateActions, SimulationStateEnum } from "../redux/slices/simulate-slice";
import { createSimulationData, lockAllCellsBut, runFastSimulationStepGenerator, runSimulationStepGenerator, unlockAllCells } from "../taskpane";
import { dispatchToast } from "../helpers/dispatch-toast";
import { ToastTypeEnum } from "../interfaces/dispatch-toast";
import { simulationParamsActions } from "../redux/slices/simulation-params-slice";
import { simulationOutputActions } from "../redux/slices/simulation-output-slice";
import { normalizeExcelCell } from "../helpers/normalize-cell";

let startDate: number = 0

export async function runSimulationStep() {
  const simulate = store.getState().simulate;
  const { runDecisionTable } = store.getState().simulationParams;

  let runner: ReturnType<typeof runSimulationStepGenerator> | null = simulate.runner;

  try {
    if (!runner) {
      const {
        simulation: simulationReference,
        simulationParams: simulationParamsReference,
        updater: updaterReference,
        recorder,
      } = await createSimulationData(runDecisionTable);

      runner = runSimulationStepGenerator(
        simulationReference,
        simulationParamsReference,
        updaterReference,
      );

      store.dispatch(simulateActions.setRunner({ runner, recorder }));
      await lockAllCellsBut(getEditableCells());

      startDate = performance.now();
    }

    const result = await runner.next();

    if (result.done) {
      await loadStats();
      await unlockAllCells();
      store.dispatch(simulateActions.setState(SimulationStateEnum.FINISHED));
      store.dispatch(simulateActions.setStep(simulate.length));
      store.dispatch(simulateActions.setRunner(null));

      let endDate = performance.now()
      console.log('-------------------------');
      console.log('Simulation took ', (endDate - startDate) / 1000, ' seconds to complete.');
      console.log('-------------------------');
      return null;
    }

    if (result.value) {
      dispatchStepUpdatesThrottled(result.value.simulationLength, result.value.simulationStep);
      return result.value;
    }
  } catch (error) {
    await unlockAllCells();
    console.error("Error: ", error);
    dispatchToast({
      type: ToastTypeEnum.ERROR,
      message: error.message,
    });
    store.dispatch(simulateActions.reset());
    throw error;
  }

  return null;
}

export async function runFastSimulationStep() {
  const simulate = store.getState().simulate;
  const { runDecisionTable } = store.getState().simulationParams;

  let runner: ReturnType<typeof runFastSimulationStepGenerator> | null = simulate.runner;

  try {
    if (!runner) {
      const {
        simulation: simulationReference,
        simulationParams: simulationParamsReference,
        updater: updaterReference,
        recorder,
      } = await createSimulationData(runDecisionTable);

      runner = runFastSimulationStepGenerator(
        simulationReference,
        simulationParamsReference,
        updaterReference,
      );

      store.dispatch(simulateActions.setRunner({ runner, recorder }));
      await lockAllCellsBut(getEditableCells());

      startDate = performance.now();
    }

    const result = await runner.next();

    if (result.done) {
      await unlockAllCells();
      store.dispatch(simulateActions.setState(SimulationStateEnum.FINISHED));
      store.dispatch(simulateActions.setStep(simulate.length));
      store.dispatch(simulateActions.setRunner(null));

      let endDate = performance.now()
      console.log('-------------------------');
      console.log('Simulation took ', (endDate - startDate) / 1000, ' seconds to complete.');
      console.log('-------------------------');
      return null;
    }

    if (result.value) {
      store.dispatch(simulateActions.setLength(result.value.simulationLength));
      store.dispatch(simulateActions.setStep(result.value.simulationStep));
      return result.value;
    }
  } catch (error) {
    await unlockAllCells();
    console.error("Error: ", error);
    dispatchToast({
      type: ToastTypeEnum.ERROR,
      message: error.message,
    });
    store.dispatch(simulateActions.reset());
    throw error;
  }

  return null;
}

export async function reset() {
  await unlockAllCells();
  store.dispatch(simulationParamsActions.setRunDecisionTable(false));
  store.dispatch(simulationParamsActions.setFastSimulation(false));
  store.dispatch(simulationOutputActions.reset());
  store.dispatch(simulateActions.reset());
}

export async function loadStats() {
  const { recorder } = store.getState().simulate;
  if (!recorder) {
    return;
  }

  store.dispatch(simulationOutputActions.replaceWith(
    recorder.get_forecast_values_stats()
  ));
}

const dispatchStepUpdatesThrottled = throttle((length: number, step: number) => {
  store.dispatch(simulateActions.setLength(length));
  store.dispatch(simulateActions.setStep(step));
}, 250);

const getEditableCells = () => {
  const { variables } = store.getState();

  const assumptionCells = variables.assumptions
    ?.filter(a => a.cell)
    ?.map(a => normalizeExcelCell(a.cell)) || [];

  const decisionCells = variables.decisions
    ?.filter(d => d.cell)
    ?.map(d => normalizeExcelCell(d.cell)) || [];

  let result = [
    ...assumptionCells,
    ...decisionCells
  ];

  return result;
}