import React, { FC } from "react";
import ExpandedCorrelationCard from "./ExpandedCorrelationCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clearBoundCell, tryCatch } from "../../taskpane";
import { removeCorrelation } from "../../redux/slices/variables-slice";
import CollapsedCorrelationCard from "./CollapsedCorrelationCard";

interface Props {
  id: string;
  disabled?: boolean;
}

const CorrelationCard: FC<Props> = ({ id, disabled }) => {
  const dispatch = useAppDispatch();

  const collapsed = useAppSelector((state) =>
    state.collapsed[id]
  );

  const storeCorrelation = useAppSelector((state) => state.variables.correlations
    .find((correlation) => correlation.id === id)
  );

  const handleRemove = () => {
    tryCatch(async () => {
      if (storeCorrelation.cell) clearBoundCell(storeCorrelation.cell);
      dispatch(removeCorrelation({ id }));
    });
  };

  return (
    <>
      {!collapsed && (
        <ExpandedCorrelationCard 
          id={id}
          disabled={disabled}
          onDelete={handleRemove}
        />
      )}
      {collapsed && (
        <CollapsedCorrelationCard
          id={id}
          disabled={disabled}
          onDelete={handleRemove}
        />
      )}
    </>
  );
}

export default CorrelationCard;
