import { ProbabilityDistributionSpec, SimulationParams } from "./probdist";
import { ExcelAssumptionUpdate } from "./updater";

export interface Assumption {
  ID: string;
  BoundCell: string;
  ProbabilityDistribution: ProbabilityDistributionSpec;
}

export interface Correlation {
  ID: string;
  BoundCell: string;
  FirstAssumptionCell: string;
  SecondAssumptionCell: string;
}

export interface Decision {
  ID: string;
  BoundCell: string;
  MinCell: string;
  MaxCell: string;
  StepCell: string;
}

export interface Forecast {
  ID: string;
  BoundCell: string;
}

export interface Simulation {
  Decisions: Decision[];
  Assumptions: Assumption[];
  Correlations: Correlation[];
  Forecasts: Forecast[];
  RunFast: (simparams: SimulationParams, asumptionUpdater: ExcelAssumptionUpdate) => void;
  Run: (simparams: SimulationParams, asumptionUpdater: ExcelAssumptionUpdate) => void;
}

export class SimulationStats {
  mean: number;
  stddev: number;
  median: number;
  forecast_values: number[];
  constructor(forecast_values: number[], mean: number, stddev: number, median: number) {
    this.forecast_values = forecast_values;
    this.mean = mean;
    this.stddev = stddev;
    this.median = median;
  }
}

export interface SimulationRecorder {
  record_trial: (
    simulationName: string,
    currentDecisionsValues: number[],
    currentAssumptionsValues: number[],
    forecastValues: number[]
  ) => void;
  reset_recording: () => void;
  get_trial_values: (simulationName: string, idx: number) => number[];
  get_trial_assumption_values: (simulationName: string, idx: number) => number[];
  get_trial_forecast_values: (simulationName: string, idx: number) => number[];
  get_forecast_values_stats: () => Map<string, Map<string, SimulationStats>>;
  dump(simulationName: string);
}

export interface ExternalCell {
 cell: string,
 formula?: string,
 value?: string 
}