import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, ProgressBar, Field, Switch } from "@fluentui/react-components";
import {
  clearBoundCell,
  getCellsAddressRange,
  interceptError,
  setSimulationParameters,
  tryCatch,
  validateActiveSheet,
} from "../../taskpane";
import InputWithLabel from "../../components/InputWithLabel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { excelCellRegex } from "../../constants/regex";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { simulationParamsActions } from "../../redux/slices/simulation-params-slice";
import { ClipboardSearch24Regular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import { SimulationStateEnum } from "../../redux/slices/simulate-slice";
import useSimulateStyles from "./useSimulateStyles";
import { MainRoutesEnum } from "../../routes/routes";
import useSimulation from "../../hooks/useSimulation";
import SimulationProgressBar from "../../components/SimulationProgressBar";
import { store } from "../../redux/store";
import useActionsDisabled from "../../hooks/useActionsDisabled";

const SimulatePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const styles = useSimulateStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  const params = useAppSelector((state) => state.simulationParams);
  const hasDecisions = useAppSelector((state) => {
    const foundValidDecision = !!state.variables.decisions?.find(d => d.cell);
    return foundValidDecision;
  });

  const {
    simulation,

    startFast,
    startOrResume,
    nextStep,
    pause,
    reset,
    cancelFast,
  } = useSimulation();

  const actionsDisabled = useActionsDisabled();

  const checkUniqueCellValue = (value: string) => {
    const allValues = [form.values.numberOfTrialsCell, form.values.seedCell];
    const allFilledValues = allValues.filter((field) => field !== "");
    const nonUniqueValues = allFilledValues.filter(
      (field) => allFilledValues.indexOf(field) !== allFilledValues.lastIndexOf(field)
    );

    return !nonUniqueValues.includes(value);
  };

  const form = useFormik({
    initialValues: {
      numberOfTrialsCell: params.numberOfTrialsCell || "",
      seedCell: params.seedCell || "",
    },
    validationSchema: Yup.object({
      numberOfTrialsCell: Yup.string()
        .optional()
        .matches(excelCellRegex, "Invalid cell format")
        .test("unique", "This cell is already used", checkUniqueCellValue),
      seedCell: Yup.string()
        .optional()
        .matches(excelCellRegex, "Invalid cell format")
        .test("unique", "This cell is already used", checkUniqueCellValue),
    }),
    onSubmit: async (values) => {
      try {
        await interceptError(async () => {
          await validateActiveSheet();

          const storedParams = store.getState().simulationParams;
          if (storedParams.numberOfTrialsCell) await clearBoundCell(storedParams.numberOfTrialsCell);
          if (storedParams.seedCell) await clearBoundCell(storedParams.seedCell);

          await setSimulationParameters({
            numOfTrialsBoundCell: values.numberOfTrialsCell,
            seedBoundCell: values.seedCell || null,
          });

          dispatch(
            simulationParamsActions.setParams({
              numberOfTrialsCell: values.numberOfTrialsCell,
              seedCell: values.seedCell || "",
            })
          );
        });
      } catch {
        form.resetForm();
      }
    },
  });

  useEffect(() => {
    form.resetForm({
      values: { ...params },
    });
  }, [params?.numberOfTrialsCell, params?.seedCell]);

  useEffect(() => {
    if (form.dirty) {
      form.submitForm();
    }
  }, [form.values.numberOfTrialsCell, form.values.seedCell]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const statusClass = styles.simulationStatus.split(' ')
      .map(c => `.${c}`)
      .join('');

    let statusElement = containerRef.current.querySelector(statusClass) as HTMLDivElement | null;
    if (!statusElement) {
      return;
    }

    statusElement.focus();
  }, [simulation?.state]);

  return (
    <div className={styles.root} ref={containerRef}>
      <form className={styles.inputForm} onSubmit={form.handleSubmit}>
        <div className={styles.inputs}>
          <InputWithLabel
            id="numberOfTrials"
            label="Number of Trials"
            name="numberOfTrialsCell"
            disabled={actionsDisabled}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.numberOfTrialsCell}
            error={form.touched.numberOfTrialsCell && form.errors?.numberOfTrialsCell}
            buttonAction={() => {
              tryCatch(async () => {
                const range = await getCellsAddressRange();
                await form.setFieldValue("numberOfTrialsCell", range, true);
              });
            }}
          />
          <InputWithLabel
            id="seed"
            label="Seed"
            name="seedCell"
            disabled={actionsDisabled}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.seedCell}
            error={form.touched.seedCell && form.errors?.seedCell}
            buttonAction={() => {
              tryCatch(async () => {
                const range = await getCellsAddressRange();
                await form.setFieldValue("seedCell", range, true);
              });
            }}
          />
        </div>
      </form>

      <SimulationProgressBar
        length={simulation.length}
        step={simulation.step}
      />

      <div className={styles.runDecisionParams}>
        <Switch
          disabled={!hasDecisions || simulation.state !== SimulationStateEnum.NOT_STARTED}
          label="Run Decision Table"
          checked={!!params.runDecisionTable}
          onChange={() => dispatch(simulationParamsActions.setRunDecisionTable(!params.runDecisionTable))}
        />
      </div>

      <div className={styles.runDecisionParams}>
        <Switch
          disabled={simulation.state !== SimulationStateEnum.NOT_STARTED}
          label="Fast Simulation"
          checked={!!params.runFastSimulatios}
          onChange={() => dispatch(simulationParamsActions.setFastSimulation(!params.runFastSimulatios))}
        />
      </div>

      {SimulationStateEnum.NOT_STARTED == simulation.state && (
        <div className={styles.buttons}>
          <Button
            onClick={() => {
              if (params.runFastSimulatios) {
                startFast();
              } else {
                startOrResume();
              }
            }}
          >
            Run Simulation
          </Button>
          <Button onClick={nextStep} disabled={params.runFastSimulatios}>
            Next Simulation Step
          </Button>
        </div>
      )}

      {SimulationStateEnum.PAUSED == simulation.state && (
        <div className={styles.buttons}>
          <Button onClick={startOrResume}>
            Resume Simulation
          </Button>
          <Button onClick={nextStep}>
            Next Simulation Step
          </Button>
          <Button onClick={reset}>
            Reset simulation
          </Button>
        </div>
      )}


      {SimulationStateEnum.RUNNING === simulation.state && (
        <>
          <div className={styles.buttons}>
            <Button onClick={pause}>
              Pause Simulation
            </Button>
          </div>
          <div
            className={styles.simulationStatus}
            tabIndex={1}
          >
            Simulation is in progress
          </div>
        </>
      )}

      {SimulationStateEnum.RUNNING_FAST === simulation.state && (
        <>
          {params.runDecisionTable && (
            <div className={styles.buttons}>
              <Button onClick={cancelFast}>
                Cancel
              </Button>
            </div>
          )}
          <div
            className={styles.simulationStatus}
            tabIndex={1}
          >
            Simulation is in progress
          </div>
        </>
      )}

      {SimulationStateEnum.CANCELING_FAST === simulation.state && (
        <>
          {params.runDecisionTable && (
            <div className={styles.buttons}>
              <Button disabled>
                Cancel
              </Button>
            </div>
          )}
          <div
            className={styles.simulationStatus}
            tabIndex={1}
          >
            Simulation is canceling
          </div>
        </>
      )}

      {SimulationStateEnum.FINISHED === simulation.state && (
        <>
          <div className={styles.buttons}>
            <Link to={MainRoutesEnum.ANALYZE_TAB}>
              <Button>
                <i className={styles.buttonIcon}>
                  <ClipboardSearch24Regular />
                </i>
                <span>Analyze</span>
              </Button>
            </Link>
            <Button onClick={reset}>
              Run Another Simulation
            </Button>
          </div>
          <div
            className={styles.simulationStatus}
            tabIndex={1}
          >
            Simulation was completed
          </div>
        </>
      )}
    </div>
  );
};

export default SimulatePage;
