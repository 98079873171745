import { expandCellRange } from "../helpers/cell-range";

export function allCustomDistributionRangesAreEqual (value: { name?: string, cell?: string }[], ctx): boolean {
  if (value.length !== 2) {
    return true;
  }

  const rangeValues = value[0]?.cell;
  const rangeProbabilities = value[1]?.cell;
  if (!rangeValues || !rangeProbabilities) {
    return true;
  }

  const values = expandCellRange(rangeValues);
  const probabilities = expandCellRange(rangeProbabilities);
  let isValid = values.length === probabilities.length 
  if (isValid) {
    return true;
  }

  return ctx.createError({
    message: "Both ranges should have the same number of cells",
    path: 'distributionParams[0].cell'
  });
};
