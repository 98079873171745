import * as d3 from "d3";
import React, { useDeferredValue, useEffect, useRef } from "react";
import useResizeObserver from "../../hooks/use-resize-observer";
import { excelColors } from "../../constants/colors";
import { HISTOGRAM_OVERFLOW, ITEM_COUNT_WITH_PADDING, X_AXIS_PADDING } from "./constants";

export interface IHistogramProps {
  data: number[];
  lowerThreshold: number;
  upperThreshold: number;
  numberOfBins: number;
}

const Histogram: React.FC<IHistogramProps> = ({
  data,
  lowerThreshold,
  upperThreshold,
  numberOfBins,
}) => {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);

  let defferedLower = useDeferredValue(lowerThreshold);
  let defferedUpper = useDeferredValue(upperThreshold);

  useEffect(() => {
    if (!dimensions) return () => {};

    const { width, height } = dimensions;
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const [min, max] = d3.extent(data);

    // Remove any existing svg before drawing the new one
    d3.select(svgRef.current).select("svg").remove();

    // Append the svg object to the div
    const svg = d3.select(svgRef.current).append("svg").attr("width", width).attr("height", height);

    // Define the histogram
    const histogram = d3
      .histogram()
      .value((d) => d)
      .domain(numberOfBins <= 1
        ? [min - HISTOGRAM_OVERFLOW, max + HISTOGRAM_OVERFLOW]
        : [min, max]
      )
      .thresholds(numberOfBins <= 1
        ? [min - HISTOGRAM_OVERFLOW, max + HISTOGRAM_OVERFLOW]
        : d3.range(min, max, (max - min) / numberOfBins));

    // Generate the bins
    const bins = histogram(data);

    // Define the scales
    const x = d3
      .scaleLinear()
      .domain(
        numberOfBins <= ITEM_COUNT_WITH_PADDING
          ? [min - X_AXIS_PADDING, max + X_AXIS_PADDING]
          : [min, max]
      )
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(bins, (d) => d.length)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Create the x-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(width / 80));

    // Create the y-axis
    svg.append("g").attr("transform", `translate(${margin.left},0)`).call(d3.axisLeft(y));

    if (!data || data.length === 0)  {
      return () => {};
    }

    // Append the bars for the histogram
    svg
      .append("g")
      .selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.x0))
      .attr("y", (d) => y(d.length))
      .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
      .attr("height", (d) => y(0) - y(d.length))
      .attr("fill", (d) => {
        const midPoint = (d.x0 + d.x1) / 2;
        return defferedLower <= midPoint && midPoint <= defferedUpper
          ? excelColors.histogramActive
          : excelColors.histogramInactive;
      })
      .on("mouseover", (event, d) => {
        d3.select(".tooltip").remove();
        // Show tooltip
        const tooltip = d3
          .select(svgRef.current)
          .append("div")
          .attr("class", "tooltip")
          .style("position", "absolute")
          .style("pointer-events", "none")
          .style("background-color", "rgba(0, 0, 0, 0.8)")
          .style("color", "white")
          .style("padding", "8px")
          .style("border-radius", "4px")
          .style("font-size", "12px")
          .style("z-index", "10")
          .style("left", event.pageX + 50 + "px")
          .style("top", event.pageY - 15 + "px");

        const delta = Math.abs(d.x1 - d.x0)
        if (delta < 1e-4) {
          tooltip.append("div").text(`Range: ${d.x0.toExponential()} - ${d.x1.toExponential()}`);
        } else {
          tooltip.append("div").text(`Range: ${d.x0.toFixed(4)} - ${d.x1.toFixed(4)}`);
        }
        tooltip.append("div").text(`Count: ${d.length}`);

        d3.select(event.currentTarget).attr("fill", excelColors.histogramSelected);
      })
      .on("mousemove", (event) => {
        // Update tooltip position
        d3.select(".tooltip")
          .style("left", event.pageX - 50 + "px")
          .style("top", event.pageY + 15 + "px");
      })
      .on("mouseout", (event) => {
        // Hide tooltip
        d3.select(".tooltip").remove();

        d3.select(event.currentTarget).attr("fill", (d) => {
        const midPoint = (d.x0 + d.x1) / 2;
        return defferedLower <= midPoint && midPoint <= defferedUpper
            ? excelColors.histogramActive
            : excelColors.histogramInactive;
        });
      });

    return () => {
      d3.select(".tooltip").remove();
    };
  }, [data, dimensions, defferedLower, defferedUpper, numberOfBins]);

  return (
    <>
      <div ref={wrapperRef} style={{ width: "100%", height: "400px" }}>
        <div ref={svgRef}></div>
      </div>
    </>
  );
};

export default Histogram;
