import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Button } from '@fluentui/react-components';
import { ArrowAutofitHeight24Filled, ArrowAutofitHeightIn24Filled } from '@fluentui/react-icons';
import { collapsedActions } from '../redux/slices/collapsed-slice';

interface Props {
  id: string;
  disabled?: boolean;
}

const ToggleCollapsedButton: FC<Props> = ({ id, disabled }) => {
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector((state) => state.collapsed[id]);

  return (
    <Button
      title={collapsed ? 'Expand' : 'Collapse'}
      disabled={disabled}
      onClick={() => dispatch(collapsedActions.set({ id, collapsed: !collapsed }))}
      icon={
        collapsed 
           ? <ArrowAutofitHeight24Filled />
           : <ArrowAutofitHeightIn24Filled />
      }
    >
    </Button>
  );
}

export default ToggleCollapsedButton;