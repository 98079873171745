import { Field, ProgressBar } from '@fluentui/react-components';
import React, { FC } from 'react';
import { makeStyles } from "@fluentui/react-components";

interface Props {
  step?: number;
  length?: number;
}

const useStyles = makeStyles({
  progress: {
    marginBottom: "2rem",
  },
});

const SimulationProgressBar: FC<Props> = ({ step, length }) => {
  const styles = useStyles();

  return (
      <div className={styles.progress}>
        <Field
          validationMessage={
            step && length ? `Step ${step} out of ${length}` : "Progress"
          }
          validationState="none"
        >
          <ProgressBar value={step && length ? step / length : 0} />
        </Field>
      </div>
  );
};

export default SimulationProgressBar;