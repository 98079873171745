import React, { useEffect } from "react";
import {
  makeStyles,
  Button,
  Card,
  Body1,
  CardHeader,
  CardFooter,
} from "@fluentui/react-components";
import { SaveRegular } from "@fluentui/react-icons";
import InputWithLabel from "../InputWithLabel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setForecastProperties } from "../../redux/slices/variables-slice";
import { clearBoundCell, setForecastAtCell, getCellsAddressRange, tryCatch, validateActiveSheet, extractFormulas } from "../../taskpane";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCellUniqueness from "../../hooks/useCellUniqueness";
import { excelCellRegex } from "../../constants/regex";
import { useCardStyles } from "../styles";
import ToggleCollapsedButton from "../ToggleCollapsedButton";
import DeleteButton from "../DeleteButton";
import isEmpty from "lodash/isEmpty";
import useActionsDisabled from "../../hooks/useActionsDisabled";
import FocusErrorOnSubmit from "../FocusErrorOnSubmit";

interface IForecastCardProps {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

const ExpandedForecastCard: React.FC<IForecastCardProps> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();
  const dispatch = useAppDispatch();

  const { 
    checkForecastCellsUniqueness,
    checkAssumptionCellsUniqueness,
    checkAssumptionDistributionParametersUniqueness,
    checkDecisionCellsUniqueness,
    checkDecisionParametersUniqueness,
    checkCorrelationUniqueness,
  } = useCellUniqueness();

  const storeForecast = useAppSelector((state) => state.variables.forecasts.find((forecast) => forecast.id === id));

  const form = useFormik({
    initialValues: {
      cell: storeForecast.cell || "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      cell: Yup.string()
        .matches(excelCellRegex, "Invalid Excel cell format")
        .test("unique", "This cell is already used for another forecast", checkForecastCellsUniqueness(id))
        .test("unique", "This cell is already used for a correlation", checkCorrelationUniqueness())
        .test("unique", "This cell is already used for an assumption", checkAssumptionCellsUniqueness(id))
        .test("unique", "This cell is already used for a distribution parameter", checkAssumptionDistributionParametersUniqueness(id))
        .test("unique", "This cell is already used for a decision", checkDecisionCellsUniqueness())
        .test("unique", "This cell is already used for a decision parameter", checkDecisionParametersUniqueness())
        .test("custom", async (value, ctx) => {
          if (!value) return true;

          try {
            const [formula] = await extractFormulas([value]);
            if (!formula) {
              return ctx.createError({
                message: "The forecast cell has no formula defined",
                path: 'cell'
              });
            }
          } catch {
            /* nothing to do here */
          }
          return true;
        })
        .required("Required"),
    }),
    onSubmit: (values) => {
      tryCatch(async () => {
        await validateActiveSheet();

        if (storeForecast.cell) await clearBoundCell(storeForecast.cell);

        await setForecastAtCell(id, values.cell);

        dispatch(setForecastProperties({ id, ...values }));

        form.resetForm({ values });
      });
    },
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusErrorOnSubmit form={form} />
      <Card>
        <CardHeader
          header={
            <Body1 className={styles.header}>
              <b>Forecast</b>

              <div className={styles.cardActions}>
                <ToggleCollapsedButton id={id} disabled={form.dirty || isEmpty(form.values.cell)}/>
                <DeleteButton disabled={disabled} onDelete={onDelete}>
                  Remove Forecast
                </DeleteButton>
              </div>
            </Body1>
          }
        />
        <Body1>
          <div className={styles.flex_even}>
            <InputWithLabel
              id={`${id}-cell`}
              name="cell"
              label="Cell*"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={disabled}
              value={form.values.cell}
              error={form.touched.cell && form.errors.cell}
              buttonAction={() => {
                tryCatch(async () => {
                  const range = await getCellsAddressRange();
                  form.setFieldValue("cell", range, true);
                });
              }}
              aria-required
              required
            />
          </div>
        </Body1>
        <CardFooter>
          <Button icon={<SaveRegular fontSize={16} />} type="submit">
            Save
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default ExpandedForecastCard;
