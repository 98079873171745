import { expandCellRange } from "../helpers/cell-range";
import { normalizeExcelCell } from "../helpers/normalize-cell";
import { useAppSelector } from "../redux/hooks";

const useCellUniqueness = () => {
  const forecasts = useAppSelector((state) => state.variables.forecasts);
  const assumptions = useAppSelector((state) => state.variables.assumptions);
  const decisions = useAppSelector((state) => state.variables.decisions);
  const correlations = useAppSelector((state) => state.variables.correlations);

  const checkForecastCellsUniqueness = (excludeForcastId?: string) => (value: string) => {
    const otherForecasts = forecasts
      .filter(forecast => excludeForcastId ? forecast.id !== excludeForcastId : true)
      .flatMap((forecast) => expandCellRange(forecast.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherForecasts.includes(normalizeExcelCell(cell)));
  };

  const checkAssumptionCellsUniqueness = (excludeAssumptionId?: string) => (value: string) => {
    const otherAssumptions = assumptions
      .filter(assumption => excludeAssumptionId ? assumption.id !== excludeAssumptionId : true)
      .flatMap(assumption => expandCellRange(assumption.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherAssumptions.includes(normalizeExcelCell(cell)));
  };

  const checkAssumptionDistributionParametersUniqueness = (excludeAssumptionId?: string) => (value: string) => {
    const otherParameters = assumptions
      .filter(assumption => excludeAssumptionId ? assumption.id !== excludeAssumptionId : true)
      .flatMap(assumption => (assumption.distributionParams || []))
      .flatMap(param => expandCellRange(param.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherParameters.includes(normalizeExcelCell(cell)));
  };

  const checkDecisionCellsUniqueness = (excludeDecisionId?: string) => (value: string) => {
    const otherDecisions = decisions
      .filter(decision => excludeDecisionId ? decision.id !== excludeDecisionId : true)
      .flatMap(decision => expandCellRange(decision.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherDecisions.includes(normalizeExcelCell(cell)));
  };

  const checkDecisionParametersUniqueness = (excludeDecisionId?: string) => (value: string) => {
    const otherParameters = decisions
      .filter(assumption => excludeDecisionId ? assumption.id !== excludeDecisionId : true)
      .flatMap((assumption) => (assumption.decisionParams || []))
      .flatMap((param) => expandCellRange(param.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherParameters.includes(normalizeExcelCell(cell)));
  };

  const checkCorrelationUniqueness = (excludeCorrelationId?: string) => (value: string) => {
    const otherCorrelations = correlations
      .filter(correlation => excludeCorrelationId ? correlation.id !== excludeCorrelationId : true)
      .flatMap(correlation => expandCellRange(correlation.cell))
      .map(normalizeExcelCell);

    const values = expandCellRange(value);
    return !values.find(cell => otherCorrelations.includes(normalizeExcelCell(cell)));
  };

  const checkUniqueness = (excludeId?: string) => (value: string) => {
    return (
      checkForecastCellsUniqueness(excludeId)(value)
      && checkAssumptionCellsUniqueness(excludeId)(value)
      && checkAssumptionDistributionParametersUniqueness(excludeId)(value)
      && checkDecisionCellsUniqueness(excludeId)(value)
      && checkDecisionParametersUniqueness(excludeId)(value)
      && checkCorrelationUniqueness(excludeId)(value)
    );
  };

  return {
    checkForecastCellsUniqueness,
    checkAssumptionCellsUniqueness,
    checkAssumptionDistributionParametersUniqueness,
    checkDecisionCellsUniqueness,
    checkDecisionParametersUniqueness,
    checkCorrelationUniqueness,
    checkUniqueness
  }
};

export default useCellUniqueness;