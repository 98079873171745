import React from "react";
import { Button, Divider, Title2 } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DecisionCard from "../../components/DecisionCard";
import { addDecision } from "../../redux/slices/variables-slice";
import { usePageStyles } from "../styles";
import useActionsDisabled from "../../hooks/useActionsDisabled";

const DecisionPage: React.FC = () => {
  const styles = usePageStyles();
  const decisions = useAppSelector((state) => state.variables.decisions);
  const dispatch = useAppDispatch();

  const actionsDisabled = useActionsDisabled();

  function handleAddDecision() {
    window.scrollTo({
      top: 96,
      behavior: "smooth",
    });
    dispatch(addDecision());
  }

  return (
    <div className={styles.root}>
      <div className={styles.newButtonSticky}>
        <div className={styles.newButtonContainer}>
          <Button
            className={styles.newButton}
            icon={<AddRegular fontSize={16} />}
            onClick={handleAddDecision}
            disabled={actionsDisabled}
          >
            Define New Decision
          </Button>
        </div>
      </div>

      {decisions.map((decision) => (
        <DecisionCard
          key={decision.id}
          id={decision.id}
          disabled={actionsDisabled}
        />
      ))}

      <Divider />
    </div>
  );
};

export default DecisionPage;
