export enum DecisionParamsEnum {
    MINIMUM = "minimum",
    MAXIMUM = "maximum",
    STEP = "step",
}

export const decisionParamsDisplayName = {
    [DecisionParamsEnum.MINIMUM]: "Minimum",
    [DecisionParamsEnum.MAXIMUM]: "Maximum",
    [DecisionParamsEnum.STEP]: "Step",
};
