import React, { FC } from "react";
import { useCardStyles } from "../styles";
import { useAppSelector } from "../../redux/hooks";
import { Card, CardHeader, Body1 } from "@fluentui/react-components";
import DeleteButton from "../DeleteButton";
import ToggleCollapsedButton from "../ToggleCollapsedButton";

interface Props {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

const CollapsedForecastCard: FC<Props> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();

  const forecast = useAppSelector((state) => state.variables.forecasts.find((forecast) => forecast.id === id));

  return (
    <Card>
      <CardHeader
        header={
          <Body1 className={styles.header}>
            <span>
              <b>{forecast.cell}</b>
              {' '} - Forecast
            </span>

            <div className={styles.cardActions}>
              <ToggleCollapsedButton id={id} />
              <DeleteButton disabled={disabled} onDelete={onDelete}>
                Remove Forecast
              </DeleteButton>
            </div>
          </Body1>
        }
      />
    </Card>
  );
};

export default CollapsedForecastCard;