export const normalizeExcelCell = (cell: string): string => {
  const cellWithoutSheet = cell.replace(/.*!/g, "");
  return cellWithoutSheet.replace(/\$/g, "").toUpperCase();
};

export const parseFloatWithZero = (s: string): number => {
  let f = Number.parseFloat(s);
  if (Number.isNaN(f)) {
    return 0.0;
  }
  return f;
};
