import React, { FC, useEffect } from 'react';
import { useAppSelector } from '../redux/hooks';
import { SimulationPesistence } from '../simulation/persistence';
import debounce from 'lodash/debounce';
import { store } from '../redux/store';

const debouncedSave = debounce(async () => {
  const collapsedState = store.getState().collapsed;

  let simstate = new SimulationPesistence();

  await simstate.load();

  simstate.add({
    collapsedState,
    assumptions: undefined,
    correlations: undefined,
    forecasts: undefined,
    decisions: undefined,
  });

  await simstate.save();
}, 250);

const PersistCollapsedState: FC = () => {
  const collapsedState = useAppSelector(state => state.collapsed);

  useEffect(() => {
     debouncedSave();
  }, [collapsedState]);

  return null;
}

export default PersistCollapsedState;