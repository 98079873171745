import { useHistory } from "react-router-dom";
import { dispatchToast } from "../helpers/dispatch-toast";
import { ToastTypeEnum } from "../interfaces/dispatch-toast";
import { useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { dirtyFormsActions } from "../redux/slices/dirty-forms-slice";
import { store } from "../redux/store";

const usePreventNavigation = (preventNavigation: boolean) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!preventNavigation) {
      return () => {};
    }

    dispatch(dirtyFormsActions.inc())
    return () => dispatch(dirtyFormsActions.dec());
  }, [preventNavigation]);

  useEffect(() => {
    history.block((nextLocation) => {
      const dirtyFormsCount = store.getState().dirtyForms.count;
      if (dirtyFormsCount === 0) {
        return true;
      }

      dispatchToast({
        type: ToastTypeEnum.WARNING,
        message: "You have some unsaved changes",
        action: {
          label: "Continue",
          onClick: () => {
            history.block(true);
            history.push(nextLocation.pathname);
          },
        },
      });

      return false;

    });
  }, [preventNavigation]);
}

export default usePreventNavigation;