export const excelColors = {
  assumption: "#d8aed5",
  decision: "#ffe599",
  forecast: "#b6d7a8",
  parameters: "#cccccc",
  histogramActive: "#6db1e6",
  histogramInactive: "#d9d9d9",
  histogramSelected: "#189981",
  simulationName: "#dcdcdc",
};
