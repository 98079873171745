import memoize from 'lodash/memoize';
import { excelCellRegex, excelRangeRegex } from "../constants/regex";
import { normalizeExcelCell } from './normalize-cell';

// Helper function to convert Excel column letters to a number (e.g., 'A' -> 1, 'Z' -> 26, 'AA' -> 27)
function columnToNumber(column: string): number {
    let num = 0;
    for (let i = 0; i < column.length; i++) {
        let charCode = column.toUpperCase().charCodeAt(i);
        num = num * 26 + (charCode - 'A'.charCodeAt(0) + 1);
    }
    return num;
}

// Helper function to convert a number to Excel column letters (e.g., 1 -> 'A', 26 -> 'Z', 27 -> 'AA')
function numberToColumn(num: number): string {
    let column = '';
    while (num > 0) {
        let modulo = (num - 1) % 26;
        column = String.fromCharCode(modulo + 'A'.charCodeAt(0)) + column;
        num = Math.floor((num - modulo) / 26);
    }
    return column;
}

export const expandCellRange = memoize((cellValue: string): string[] => {
    if (excelCellRegex?.test(cellValue)) {
        return [normalizeExcelCell(cellValue)];
    }

    const rangeMatch = cellValue?.match(/^([a-zA-Z]+)(\d+):([a-zA-Z]+)(\d+)$/);
    if (!rangeMatch) {
        return [];
    }

    const startCol = rangeMatch[1];
    const startRow = parseInt(rangeMatch[2]);
    const endCol = rangeMatch[3];
    const endRow = parseInt(rangeMatch[4]);

    const startColNum = columnToNumber(startCol);
    const endColNum = columnToNumber(endCol);

    // Check for valid range
    if (startColNum > endColNum || startRow > endRow) {
        return [];
    }

    const cells = [];

    // Generate cells in the range
    for (let colNum = startColNum; colNum <= endColNum; colNum++) {
        for (let rowNum = startRow; rowNum <= endRow; rowNum++) {
            cells.push(numberToColumn(colNum) + rowNum);
        }
    }

    return cells.map(normalizeExcelCell);
});

export const areRangeCellsInSameColumn = (range: string): boolean => {
    const cells = expandCellRange(range);

    if (cells.length === 0) {
        return false;
    }

    function extractColumn(cell: string): string {
        const match = cell.match(/^([A-Z]+)/);
        if (!match) {
            return "";
        }
        return match[1];
    }

    const firstColumn = extractColumn(cells[0]);

    for (let i = 1; i < cells.length; i++) {
        if (extractColumn(cells[i]) !== firstColumn) {
            return false;
        }
    }

    return true;
}

export const areRangeCellsInSameRow = (range: string): boolean => {
    const cells = expandCellRange(range);

    if (cells.length === 0) {
        return false;
    }

    function extractRow(cell: string) {
        const match = cell.match(/(\d+)$/);
        if (!match) {
            return -1;
        }
        return parseInt(match[1], 10);
    }

    const firstRow = extractRow(cells[0]);

    for (let i = 1; i < cells.length; i++) {
        if (extractRow(cells[i]) !== firstRow) {
            return false;
        }
    }

    return true;
}