import { makeStyles, tokens } from '@fluentui/react-components';
import React, { FC, PropsWithChildren } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useArrowNavigationGroup } from '@fluentui/react-tabster';


const useStyles = makeStyles({
  wrapper: {
    width: '100%',

    flexShrink: 0,

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    background: 'white',
  },
  filler: {
    flex: '1 1 auto',
    borderBottom: `1px solid ${tokens.colorNeutralStroke1Hover}`,
    background: tokens.colorNeutralBackground2Hover,
  },
  scrollContainer: {
    width: '100%',
    position: 'sticky',
    top: '0',
    zIndex: 2,
  }
});

const Tabs: FC<PropsWithChildren> = ({ children }) => {
  const styles = useStyles();
  const attrs = useArrowNavigationGroup({ axis: "horizontal", circular: true });

  return (
    <ScrollContainer className={styles.scrollContainer}>
      <div role="tablist" {...attrs} className={styles.wrapper}>
        {children}
        <div tabIndex={-1} className={styles.filler}></div>
      </div>
    </ScrollContainer>
  );
};

export default Tabs;