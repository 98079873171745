
import { buildTree } from 'excel-formula-ast';
import { tokenize } from 'excel-formula-tokenizer';
import memoize from 'lodash/memoize';
import { expandCellRange } from '../helpers/cell-range';
import { Assumption, Decision, Forecast } from './types';
import { InvalidSimulationError } from './errors';
import { normalizeExcelCell } from '../helpers/normalize-cell';
import { getNthColumn } from './utils';
import _ from 'lodash';

const getASTTree = memoize((formula: string) => {
  if (formula[0] === '=') {
    const tokens = tokenize(formula.slice(1));
    return buildTree(tokens);
  }

  return buildTree(tokenize(formula));
});

const FORMULA_SEPARATOR = ', ';
export const buildNewFormula = (formula: string, replace: (_: string) => string) => {
  function visit(node) {
    if (!node) {
      return '';
    }

    switch(node.type) {
      case 'cell':
        return replace(node.key);
      case 'cell-range':
        const cells = expandCellRange(`${node.left.key}:${node.right.key}`);

        return cells.map(replace).join(FORMULA_SEPARATOR)
      case 'function':
        const argumests = node.arguments?.map(visit);

        return `${node.name}(${argumests.join(FORMULA_SEPARATOR)})`;
      case 'text':
      case 'number':
        return node.value;
      case 'logical':
        return node.value ? 'TRUE' : 'FALSE';
      case 'binary-expression':
        let left = visit(node.left);
        let right = visit(node.right);

        return `${left} ${node.operator} ${right}`;
      case 'unary-expression':
        let operand = visit(node.operand);

        return `${node.operator}${operand}`;
      default:
        throw 'not supported type'
    }
  };

  return '=' + visit(getASTTree(formula));
}

export const getAllCellsUsedBy = (formula: string): string[] => {
  function visit(node) {
    if (!node) {
      return [];
    }

    switch(node.type) {
      case 'cell':
        return [normalizeExcelCell(node.key)];
      case 'cell-range':
        const cellRange = `${node.left.key}:${node.right.key}`;
        return expandCellRange(cellRange);
      case 'function':
        return node.arguments?.flatMap(visit);
      case 'text':
      case 'number':
      case 'logical':
        return [];
      case 'binary-expression':
        let left = visit(node.left);
        let right = visit(node.right);

        return left.concat(right);
      case 'unary-expression':
        return visit(node.operand);
      default:
        return []
    }
  };

  return visit(getASTTree(formula));
}

interface CellMapping {
  cell: string,
  col: string,
  row: number
}

export class CellMapper {
  private cellMap: Record<string, CellMapping> = {};
  private lastPosition = 0;
  private destinationColumn = 'A';

  public BuildMappingFor(cell: string) {
    if (!this.cellMap[cell]) {
      let col = getNthColumn(this.destinationColumn, this.lastPosition++);
      this.cellMap[cell] = {
        cell: `${col}1`,
        col: col,
        row: 1,
      }
    }

    return this.cellMap[cell];
  }

  public MapAssumptions(assumptions: Assumption[] = []) {
    assumptions?.forEach(a => this.BuildMappingFor(a.BoundCell));
  }

  public MapDecisions(decisions: Decision[]) {
    decisions?.forEach(d => this.BuildMappingFor(d.BoundCell));
  }

  public MapForecasts(forecasts: Forecast[]) {
    forecasts?.forEach(f => this.BuildMappingFor(f.BoundCell));
  }

  public MapIntermediateCells(intermediateCells: string[]) {
    intermediateCells?.forEach(i => this.BuildMappingFor(i));
  }

  public GetMapping(cell: string): CellMapping {
    if (this.cellMap[cell]) {
      return this.cellMap[cell];
    }

    throw new InvalidSimulationError(`Invalid cell used ${cell}.`);
  }

  public Has(cell: string): boolean {
    return !!this.cellMap[cell];
  }

  public BuildRangeBetween(firstCell: string, start: number, lastCell: string, end: number) {
    let first = this.GetMapping(firstCell);
    let last = this.GetMapping(lastCell);

    return `${first.col}${start}:${last.col}${end}`;
  }
}