import memoize from "lodash/memoize";
import { BuilderNotFoundError, InvalidCorrelationParamsError } from "./errors";
import { BinomialSpec, ProbabilityDistributionSpec } from "./probdist";

var rb_uniform = require("@stdlib/random-base-randu");

import * as jStat from "jstat";

interface jStatInvMapping {
  [key: string]: (params) => any;
}

const jStatDisMapping: jStatInvMapping = {
  // CustomSpec: (params) => //TODO implement custom dist ,
  BinomialSpec: (params) => jStat.binomial(params.numberOfTrials, params.probabilityOfSuccess),
  UniformContinuousSpec: (params) => jStat.uniform(params.minimum, params.maximum),
  UniformDiscreteSpec: (params) => jStat.uniform(params.minimum, params.maximum),
  NormalSpec: (params) => new jStat.normal(params.mean, params.standardDeviation),
  TriangularSpec: (params) => jStat.triangular(params.minimum, params.maximum, params.mode),
};

function binomialInverse (p: number, n: number, prob: number): number {
  // Iterate over possible number of successes to find the quantile
  for (let k = 0; k <= n; k++) {
      if (jStat.binomial.cdf(k, n, prob) >= p) {
          return k;
      }
  }

  // If no valid k is found, return the maximum number of successes
  return n;
}

function generateCopula(rows: number, columns: number, correlation: number[][]) {
  //https://en.wikipedia.org/wiki/Copula_(probability_theory)

  //Create uncorrelated standard normal samples
  var normSamples: number[][] = jStat.randn(rows, columns);

  //Create lower triangular cholesky decomposition of correlation matrix
  var A = jStat.jStat(jStat.cholesky(correlation));

  //Create correlated samples through matrix multiplication
  var normCorrSamples = A.multiply(normSamples);

  //Convert to uniform correlated samples over 0,1 using normal CDF
  var normDist = jStat.normal(0, 1);
  var uniformCorrSamples = normCorrSamples.map(function (x) {
    return normDist.cdf(x);
  });

  return uniformCorrSamples;
}

const generateCorrelatedSamples = memoize((
  numTrials: number,
  _seed: number, // Used as a key by memoize
  correlation_factor: number,
  vars: ProbabilityDistributionSpec[]
): number[][] => {
  if (!vars || !(vars.length == 2)) {
    throw new InvalidCorrelationParamsError("expected exactly 2 items in vars param");
  }
  console.log("correlation_factor", correlation_factor);
  //Create uniform correlated copula
  let correlationMatrix: number[][] = [
    [1, correlation_factor],
    [correlation_factor, 1],
  ];

  let copula = generateCopula(2, numTrials, correlationMatrix);

  //Create unique lognormal distribution for each marginal
  let dists = vars.map((x) => {
    let name = x.Name;
    if (!name.endsWith("Spec")) {
      name = name + "Spec";
    }
    if (!Object.prototype.hasOwnProperty.call(jStatDisMapping, name)) {
      throw new BuilderNotFoundError("cannot find a nuilder for name=" + name);
    }
    let jStatMappingGenerator = jStatDisMapping[name];

    let result = x.ApplyParams(jStatMappingGenerator);
    console.log("name=", x.Name, "result=", result);
    return result;
  });

  //Generate correlated samples using the inverse transform method:
  //https://en.wikipedia.org/wiki/Inverse_transform_sampling
  let correlatedSamples: number[][] = copula.map(function (x, row, col) {
    //console.log("x=", x, "row=", row, "col=", col);
    let fn = dists[row];
    col;
    if (vars[row].Name === "Binomial") {
      let b: BinomialSpec = vars[row] as BinomialSpec;
      return binomialInverse(x, b.GetNumberOfTrials(), b.GetProbabilityOfSuccess());
    } else {
      return fn.inv(x);
    }
  });
  console.log(`[Corr] generating correlated samples`);
  return correlatedSamples;
}, (...args) => JSON.stringify(args))

function setCorrelationRandomSeed(seed?: number) {
  if (!seed) {
    jStat.setRandom(Math.random);
    return;
  }

  let uf = rb_uniform.factory({ seed });
  jStat.setRandom(uf);
}

export { generateCopula, generateCorrelatedSamples, setCorrelationRandomSeed };