import { InvalidDistributionParams, NotImplementedError } from "./errors";

var rb_triangular = require("@stdlib/random-base-triangular");
var rb_uniform = require("@stdlib/random-base-randu");
var rb_binomial = require("@stdlib/random-base-binomial");
var rb_norm = require("@stdlib/random-base-normal");

export interface SimulationParams {
  NumTrials: number;
  Seed: number;
  RunDecisionTable: boolean;
}

export interface ParameterEvaluator {
  single_values(cells: string[]): Promise<number[]>;
  multiple_values(ranges: string[]): Promise<number[][]>;
}

export interface ProbabilityDistributionSpec {
  Name: string;
  Factory: (simparams: SimulationParams) => () => number;
  SetupParams(evaluator: ParameterEvaluator): Promise<void>;
  GetParamCells: () => string[];
  ApplyParams?<T>(g: (params) => T): T;
}

// Utility function to find ceiling of r in arr[l..h]  
function findCeil(arr, r, l, h) 
{  
    let mid;  
    while (l < h) 
    {  
        mid = l + ((h - l) >> 1); // Same as mid = (l+h)/2  
        (r > arr[mid]) ? (l = mid + 1) : (h = mid);  
    }  
    return (arr[l] >= r) ? l : -1;  
}  

// The main function that returns a random number 
// from arr[] according to distribution array  
// defined by freq[]. n is size of arrays.  
function myRand(randomFactory: () => number, arr: number[], freq: number[]) {  
    // Create and fill prefix array  
    let prefix= []; 
    let i;  
    prefix[0] = freq[0];  
    for (i = 1; i < arr.length; ++i)  
        prefix[i] = prefix[i - 1] + freq[i];  

    // prefix[n-1] is sum of all frequencies. 
    // Generate a random number with  
    // value from 1 to this sum  
    let r = Math.floor((randomFactory() * prefix[arr.length - 1])) + 1;  

    // Find index of ceiling of r in prefix array 
    let indexc = findCeil(prefix, r, 0, arr.length - 1);
    return arr[indexc];  
}

export class UniformParams {
  constructor(public minimum: number = 0, public maximum: number = 10) {}
}
export class UniformContinuousSpec implements ProbabilityDistributionSpec {
  constructor(
    private minimum: string = "",
    private maximum: string = "",
    private params: UniformParams = new UniformParams()
  ) {}
  public Name = "UniformContinuous";

  GetParamCells(): string[] {
    return [this.minimum, this.maximum];
  }
  Factory(simparams: SimulationParams): () => number {
    var uf: () => number;
    let min = this.params.minimum;
    let max = this.params.maximum;
    if (Number.isFinite(simparams.Seed)) {
      uf = rb_uniform.factory({ seed: simparams.Seed });
    } else {
      uf = rb_uniform.factory();
    }
    return () => {
      return min + uf() * (max - min);
    };
  }

  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.single_values([this.minimum, this.maximum]);
    this.params = new UniformParams(...paramValues);
    if (this.params.minimum >= this.params.maximum) {
      throw new InvalidDistributionParams("Maximum value does not exceed minimum value");
    }
  }
  withParams(p: UniformParams): UniformContinuousSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}

export class UniformDiscreteSpec implements ProbabilityDistributionSpec {
  constructor(
    private minimum: string = "",
    private maximum: string = "",
    private params: UniformParams = new UniformParams(1, 10)
  ) {}
  public Name = "UniformDiscrete";
  GetParamCells(): string[] {
    return [this.minimum, this.maximum];
  }
  Factory(simparams: SimulationParams): () => number {
    var uf: () => number;
    if (Number.isFinite(simparams.Seed)) {
      uf = rb_uniform.factory({ seed: simparams.Seed });
    } else {
      uf = rb_uniform.factory();
    }
    let min = this.params.minimum;
    let max = this.params.maximum;
    return () => {
      let k = 1.0 / (max + 1 - min);
      let v = min;
      let pp = k;
      let u = uf();
      while (u > pp) {
        pp = pp + k;
        v = v + 1;
      }
      // The above while block can loop one extra time, potentially pushing `v` above `max`
      // when `u` is close enought to `pp`
      return Math.min(v, max);
    };
  }
  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.single_values([this.minimum, this.maximum]);
    this.params = new UniformParams(...paramValues);
    if (this.params.minimum >= this.params.maximum) {
      throw new InvalidDistributionParams("Maximum value does not exceed minimum value");
    }
  }
  withParams(p: UniformParams): UniformDiscreteSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}

export class TriangularParams {
  constructor(public minimum: number = 1, public maximum: number = 10, public mode: number = 5) {}
}
export class TriangularSpec implements ProbabilityDistributionSpec {
  /*
   * @param a -minimum- left of trianglel
   * @param b -maximum- right of triangle
   * @param c -mode- likeliest of triangle
   */
  constructor(
    private minimum: string = "",
    private maximum: string = "",
    private mode: string = "",
    private params: TriangularParams = new TriangularParams(1, 10, 5)
  ) {}
  public Name = "Triangular";
  GetParamCells(): string[] {
    return [this.minimum, this.maximum, this.mode];
  }
  Factory(simparams: SimulationParams): () => number {
    let min = this.params.minimum;
    let max = this.params.maximum;
    let mode = this.params.mode;
    if (Number.isFinite(simparams.Seed)) {
      return rb_triangular.factory(min, max, mode, { seed: simparams.Seed });
    }
    return rb_triangular.factory(min, max, mode);
  }

  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.single_values([this.minimum, this.maximum, this.mode]);
    this.params = new TriangularParams(...paramValues);
    if (this.params.minimum >= this.params.maximum) {
      throw new InvalidDistributionParams("Maximum value does not exceed minimum value");
    }
    if ((this.params.mode <= this.params.minimum) || (this.params.mode >= this.params.maximum)) {
      throw new InvalidDistributionParams("Mode value should be in (MIN, MAX) range");
    }
  }
  withParams(p: TriangularParams): TriangularSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}

export class NormalParams {
  constructor(public mean: number = 10, public standardDeviation = 2) {}
}
export class NormalSpec implements ProbabilityDistributionSpec {
  constructor(private mean: string = "", private standardDeviation: string = "", private params = new NormalParams()) {}
  public Name = "Normal";
  GetParamCells(): string[] {
    return [this.mean, this.standardDeviation];
  }
  Factory(simparams: SimulationParams): () => number {
    let mean = this.params.mean;
    let std_dev = this.params.standardDeviation;
    if (Number.isFinite(simparams.Seed)) {
      return rb_norm.factory(mean, std_dev, { seed: simparams.Seed });
    }
    return rb_norm.factory(mean, std_dev);
  }
  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.single_values([this.mean, this.standardDeviation]);
    this.params = new NormalParams(...paramValues);
    if (this.params.standardDeviation <= 0) {
      throw new InvalidDistributionParams("Stddev must be greater than zero");
    }
  }
  withParams(p: NormalParams): NormalSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}

export class BinomialParams {
  constructor(public numberOfTrials: number = 1, public probabilityOfSuccess: number = 0.5) {}
}

export class BinomialSpec implements ProbabilityDistributionSpec {
  /*
   * @param size Number of Bernoulli trials to be summed up. Defaults to 1
   * @param success_probability Probability of a "success". Defaults to 0.5
   * */
  constructor(
    private numberOfTrials: string = "",
    private probabilityOfSuccess: string = "",
    private params = new BinomialParams()
  ) {}
  public Name = "Binomial";
  GetParamCells(): string[] {
    return [this.numberOfTrials, this.probabilityOfSuccess];
  }
  
  GetNumberOfTrials(): number {
    return this.params.numberOfTrials;
  }
  GetProbabilityOfSuccess(): number {
    return this.params.probabilityOfSuccess;
  }

  Factory(simparams: SimulationParams): () => number {
    let size = this.params.numberOfTrials;
    let success_probability = this.params.probabilityOfSuccess;
    if (Number.isFinite(simparams.Seed)) {
      return rb_binomial.factory(size, success_probability, { seed: simparams.Seed });
    }
    return rb_binomial.factory(size, success_probability);
  }
  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.single_values([this.numberOfTrials, this.probabilityOfSuccess]);
    this.params = new BinomialParams(...paramValues);
    if (this.params.probabilityOfSuccess >= 1 || this.params.probabilityOfSuccess <= 0) {
      throw new InvalidDistributionParams("Probability should be defined and in (0, 1) range");
    }

    if (!Number.isInteger(this.params.numberOfTrials) || this.params.numberOfTrials < 1) {
      throw new InvalidDistributionParams("Number of trials must be a defined, positive integer");
    }
  }
  withParams(p: BinomialParams): BinomialSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}

export class CustomParams {
  constructor(public values: number[] = [], public probabilities: number[] = []) {}
}

export class CustomSpec implements ProbabilityDistributionSpec {
  constructor(
    private valuesRange: string,
    private probabilitiesRange: string,
    private params: CustomParams = new CustomParams()
  ) {}
  public Name = "Custom";
  Factory(simparams: SimulationParams): () => number {
    let uf: () => number;

    if (Number.isFinite(simparams.Seed)) {
      uf = rb_uniform.factory({ seed: simparams.Seed });
    } else {
      uf = rb_uniform.factory();
    }

    return () => {
      return myRand(uf, this.params.values, this.params.probabilities.map((x) => x * 100));
    };
  }
  GetParamCells(): string[] {
    return [this.valuesRange, this.probabilitiesRange];
  }
  async SetupParams(evaluator: ParameterEvaluator): Promise<void> {
    let paramValues = await evaluator.multiple_values([this.valuesRange, this.probabilitiesRange]);
    console.log(`setupparams ${paramValues}`);
    this.params = new CustomParams(...paramValues);
   
    for (let i = 0; i < this.params.probabilities.length; i++) {
      if (this.params.probabilities[i] <= 0 || this.params.probabilities[i] >= 1) {
        throw new InvalidDistributionParams("Probability should be defined and in (0, 1) range");
      }
    }
    
    const sum = this.params.probabilities.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (Math.abs(sum - 1) > 0.0001 && this.params.probabilities.length > 0) {
      throw new InvalidDistributionParams(`Sum of defined probabilities should be 1, not ${sum}`);
    }
    
    if (this.params.values.length && this.params.probabilities.length === 0) {
      let prob = 1 / this.params.values.length;
      this.params.probabilities = Array(this.params.values.length).fill(prob);
    }
  }
  withParams(p: CustomParams): CustomSpec {
    this.params = p;
    return this;
  }

  ApplyParams<T>(g: (params) => T): T {
    return g(this.params);
  }
}
