import { configureStore } from "@reduxjs/toolkit";
import variablesReducer from "./slices/variables-slice";
import simulationParamsReducer from "./slices/simulation-params-slice";
import simulateReducer from "./slices/simulate-slice";
import simulationOutputReducer from "./slices/simulation-output-slice";
import collapsedReducer from "./slices/collapsed-slice";
import dirtyFormsReducer from "./slices/dirty-forms-slice";

export const store = configureStore({
  reducer: {
    variables: variablesReducer,
    simulationParams: simulationParamsReducer,
    simulate: simulateReducer,
    simulationOutput: simulationOutputReducer,
    collapsed: collapsedReducer,
    dirtyForms: dirtyFormsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
