export enum DistributionsEnum {
  NORMAL = "Normal",
  UNIFORM_DISCRETE = "UniformDiscrete",
  UNIFORM_CONTINUOUS = "UniformContinuous",
  TRIANGULAR = "Triangular",
  BINOMIAL = "Binomial",
  CUSTOM = "Custom",
}

export enum DistributionParamsEnum1 {
  MEAN = "mean",
  STANDARD_DEVIATION = "std_dev",
  MINIMUM = "min",
  MAXIMUM = "max",
  MODE = "mode",
  NUMBER_OF_TRIALS = "size",
  PROBABILITY_OF_SUCCESS = "success_probability",
  PROBABILITIES_RANGE = "probabilities_range",
  VALUES_RANGE = "values_range",
}

export enum DistributionParamsEnum {
  MEAN = "mean",
  STANDARD_DEVIATION = "standardDeviation",
  MINIMUM = "minimum",
  MAXIMUM = "maximum",
  MODE = "mode",
  NUMBER_OF_TRIALS = "numberOfTrials",
  PROBABILITY_OF_SUCCESS = "probabilityOfSuccess",
  PROBABILITIES_RANGE = "probabilitiesRange",
  VALUES_RANGE = "valuesRange",
}

// TODO: Maybe use i18n for display names
export const distributionParamsDisplayName = {
  [DistributionParamsEnum.MEAN]: "Mean",
  [DistributionParamsEnum.STANDARD_DEVIATION]: "Standard Deviation",
  [DistributionParamsEnum.MINIMUM]: "Minimum",
  [DistributionParamsEnum.MAXIMUM]: "Maximum",
  [DistributionParamsEnum.MODE]: "Mode",
  [DistributionParamsEnum.NUMBER_OF_TRIALS]: "Number of Trials",
  [DistributionParamsEnum.PROBABILITY_OF_SUCCESS]: "Probability of Success",
  [DistributionParamsEnum.PROBABILITIES_RANGE]: "Cell range of probabilities ",
  [DistributionParamsEnum.VALUES_RANGE]: "Cell range of values",
};

export const distributionParams = {
  [DistributionsEnum.NORMAL]: [DistributionParamsEnum.MEAN, DistributionParamsEnum.STANDARD_DEVIATION],
  [DistributionsEnum.UNIFORM_DISCRETE]: [DistributionParamsEnum.MINIMUM, DistributionParamsEnum.MAXIMUM],
  [DistributionsEnum.UNIFORM_CONTINUOUS]: [DistributionParamsEnum.MINIMUM, DistributionParamsEnum.MAXIMUM],
  [DistributionsEnum.TRIANGULAR]: [
    DistributionParamsEnum.MINIMUM,
    DistributionParamsEnum.MAXIMUM,
    DistributionParamsEnum.MODE,
  ],
  [DistributionsEnum.BINOMIAL]: [
    DistributionParamsEnum.NUMBER_OF_TRIALS,
    DistributionParamsEnum.PROBABILITY_OF_SUCCESS,
  ],
  [DistributionsEnum.CUSTOM]: [DistributionParamsEnum.VALUES_RANGE, DistributionParamsEnum.PROBABILITIES_RANGE],
};
