import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { runFastSimulationStepGenerator } from "../../taskpane";
import { SimpleRecorder } from "../../simulation/recorder";

export enum SimulationStateEnum {
  NOT_STARTED = 'not_started',
  PAUSED = 'paused',
  RUNNING = 'running',
  RUNNING_FAST = 'running_fast',
  CANCELING_FAST = 'canceling_fast',
  FINISHED = 'finished',
}

type SimulationRunner = ReturnType<typeof runFastSimulationStepGenerator>;

interface SimulateState {
  state?: SimulationStateEnum;
  length?: number;
  step?: number;
  runner: SimulationRunner | null;
  recorder: SimpleRecorder | null;
}

const simulateSlice = createSlice({
  name: "simulate",
  initialState: {
    state: SimulationStateEnum.NOT_STARTED,
    runner: null,
    recorder: null,
  } as SimulateState,
  reducers: {
    setState(simulateState, action: PayloadAction<SimulationStateEnum>) {
      simulateState.state = action.payload;
    },
    setLength(simulateState, action: PayloadAction<number>) {
      simulateState.length = action.payload;
    },
    setStep(simulateState, action: PayloadAction<number>) {
      simulateState.step = action.payload;
    },
    reset(simulateState) {
      simulateState.state = SimulationStateEnum.NOT_STARTED;
      simulateState.length = undefined;
      simulateState.step = undefined;
      simulateState.runner = null;
      simulateState.recorder = null;
    },
    setRunner(simulateState, action: PayloadAction<{ runner: SimulationRunner, recorder: SimpleRecorder } | null>) {
      simulateState.runner = action.payload?.runner || null;
      simulateState.recorder = action.payload?.recorder || null;
    },
  },
})

export const simulateActions = simulateSlice.actions;

export default simulateSlice.reducer;