/* global Excel */

import {
  ProbabilityDistributionSpec,
  UniformDiscreteSpec,
  BinomialSpec,
  CustomSpec,
  NormalSpec,
  TriangularSpec,
  UniformContinuousSpec,
} from "./probdist";

export interface PersistedItems {
  assumptions: Assumption[];
  correlations: Correlation[];
  decisions: Decision[];
  forecasts: Forecast[];
  numberOfTrialsCell?: string;
  seedCell?: string;
  collapsedState?: CollapsedState;
}
const PersistenceIdKey: string = "monte_carlo_state_id";
const XMLNS = "xmlns='http://schemas.contoso.com/monte_carlo_state/1.0'";
const StartTag = `<state ${XMLNS}>`;
const ENDTag = `</state>`;

import { distributionParamsDisplayName, DistributionParamsEnum } from "../enums/distributions";
import { BuilderNotFoundError, InvalidDisplayName } from "./errors";
import { Assumption, Correlation, Decision, Forecast } from "./types";

interface ParameterNameMapping {
  [key: string]: string;
}
export class ParamNameBuilder {
  constructor(private mapping: ParameterNameMapping = {}) {
    for (const key in DistributionParamsEnum) {
      if (!Object.prototype.hasOwnProperty.call(DistributionParamsEnum, key)) continue;
      let k = DistributionParamsEnum[key];
      let mkey = distributionParamsDisplayName[k];
      this.mapping[mkey] = DistributionParamsEnum[key];
      console.log("mapping from ", mkey, "to", this.mapping[mkey]);
    }
  }
  param_name(displayName: string): string {
    if (!Object.prototype.hasOwnProperty.call(this.mapping, displayName)) {
      throw new InvalidDisplayName(displayName + " is not mapped to param name");
    }
    return this.mapping[displayName];
  }
}

interface BuilderMap {
  [key: string]: (p: any) => ProbabilityDistributionSpec;
}

const builders: BuilderMap = {
  CustomSpec: (params) => new CustomSpec(params.valuesRange, params.probabilitiesRange),
  BinomialSpec: (params) => new BinomialSpec(params.numberOfTrials, params.probabilityOfSuccess),
  UniformContinuousSpec: (params) => new UniformContinuousSpec(params.minimum, params.maximum),
  UniformDiscreteSpec: (params) => new UniformDiscreteSpec(params.minimum, params.maximum),
  NormalSpec: (params) => new NormalSpec(params.mean, params.standardDeviation),
  TriangularSpec: (params) => new TriangularSpec(params.minimum, params.maximum, params.mode),
};

function createSpec(name: string, params: any): ProbabilityDistributionSpec {
  if (!name.endsWith("Spec")) {
    name = name + "Spec";
  }
  let builder = builders[name];
  if (!builder) {
    throw new BuilderNotFoundError("no builder configured for name=" + name);
  }
  return builder(params);
}

export class ExcelDecision implements Decision {
  public ID: string;
  public BoundCell: string;
  public MinCell: string;
  public MaxCell: string;
  public StepCell: string;
  constructor(public entry: Decision) {
    this.ID = entry.ID;
    this.BoundCell = entry.BoundCell;
    this.MinCell = entry.MinCell;
    this.MaxCell = entry.MaxCell;
    this.StepCell = entry.StepCell;
  }
}

export class ExcelAssumption implements Assumption {
  public ID: string;
  public ProbabilityDistribution: ProbabilityDistributionSpec = undefined;
  public BoundCell: string;
  constructor(public entry: Assumption) {
    this.BoundCell = entry.BoundCell;
    let kind = entry.ProbabilityDistribution.Name;
    let params: any = entry.ProbabilityDistribution;
    this.ID = entry.ID;
    this.ProbabilityDistribution = createSpec(kind, params);
  }
}

export class ExcelCorrelation implements Correlation {
  public ID: string;
  public BoundCell: string;
  public FirstAssumptionCell: string;
  public SecondAssumptionCell: string;

  constructor(public entry: Correlation) {
    this.ID = entry.ID;
    this.BoundCell = entry.BoundCell;
    this.FirstAssumptionCell = entry.FirstAssumptionCell;
    this.SecondAssumptionCell = entry.SecondAssumptionCell;
  }
}

export class ExcelForecast implements Forecast {
  constructor(public ID: string, public BoundCell: string) { }
}

type CollapsedState = Record<string, boolean>;

export class SimulationPesistence {
  private assumptions: Assumption[] = [];
  private correlations: Correlation[] = [];
  private decisions: Decision[] = [];
  private forecasts: Forecast[] = [];
  private numberOfTrialsCell: string = "";
  private seedCell: string = "";
  private collapsedState: CollapsedState = {};

  empty_state(): PersistedItems {
    return { assumptions: [], forecasts: [], decisions: [], correlations: [] };
  }

  get Assumptions(): Assumption[] {
    return this.assumptions;
  }
  get Correlations(): Correlation[] {
    return this.correlations;
  }

  get Decisions(): Decision[] {
    return this.decisions;
  }
  get Forecasts(): Forecast[] {
    return this.forecasts;
  }
  get NumberOfTrialsCell(): string {
    return this.numberOfTrialsCell;
  }
  get SeedCell(): string {
    return this.seedCell;
  }

  get CollapsedState(): CollapsedState {
    return this.collapsedState;
  }

  current_state(): PersistedItems {
    return {
      assumptions: this.assumptions,
      correlations: this.correlations,
      decisions: this.decisions,
      forecasts: this.forecasts,
      numberOfTrialsCell: this.numberOfTrialsCell,
      seedCell: this.seedCell,
      collapsedState: this.collapsedState,
    };
  }

  async load(): Promise<boolean> {
    return await Excel.run(async (context) => {
      const customDocProperties = context.workbook.properties.custom;
      let customProperty: Excel.CustomProperty;

      customProperty = customDocProperties.getItemOrNullObject(PersistenceIdKey).load("value");
      await context.sync();
      let xmlId = customProperty.value;
      if (xmlId) {
        const customXmlPart = context.workbook.customXmlParts.getItem(xmlId);
        let xmlBlob = customXmlPart.getXml();
        await context.sync();
        let xmlValue = xmlBlob.value;
        let jsonValue = xmlValue.slice(StartTag.length);
        jsonValue = jsonValue.slice(0, jsonValue.length - ENDTag.length);
        let state = JSON.parse(jsonValue);
        this.forecasts = state.forecasts || [];
        this.decisions = (state.decisions || []).map((a) => new ExcelDecision(a));
        this.assumptions = (state.assumptions || []).map((a) => new ExcelAssumption(a));
        this.correlations = (state.correlations || []).map((a) => new ExcelCorrelation(a));
        this.numberOfTrialsCell = state.numberOfTrialsCell || "";
        this.seedCell = state.seedCell || "";
        this.collapsedState = state.collapsedState || {};
        return true;
      }
      const jsonValue = JSON.stringify(this.empty_state());
      let stateXmlValue = `${StartTag}${jsonValue}${ENDTag}`;
      const customXmlPart = context.workbook.customXmlParts.add(stateXmlValue);
      customXmlPart.load("id");
      await context.sync();
      customDocProperties.add(PersistenceIdKey, customXmlPart.id);
      await context.sync();
      this.assumptions = [];
      this.decisions = [];
      this.forecasts = [];
      this.correlations = [];
      this.numberOfTrialsCell = "";
      this.seedCell = "";
      this.collapsedState = {};
      return true;
    });
  }

  async save(): Promise<boolean> {
    return await Excel.run(async (context) => {
      const customDocProperties = context.workbook.properties.custom;
      let customProperty: Excel.CustomProperty;

      customProperty = customDocProperties.getItemOrNullObject(PersistenceIdKey).load("value");
      await context.sync();
      let xmlId = customProperty.value;
      const jsonValue = JSON.stringify(this.current_state());
      let stateXmlValue = `${StartTag}${jsonValue}${ENDTag}`;
      if (xmlId) {
        const customXmlPart = context.workbook.customXmlParts.getItem(xmlId);
        customXmlPart.setXml(stateXmlValue);
        await context.sync();
        return true;
      }
      const customXmlPart = context.workbook.customXmlParts.add(stateXmlValue);
      customXmlPart.load("id");
      await context.sync();
      customDocProperties.add(PersistenceIdKey, customXmlPart.id);
      await context.sync();
      return true;
    });
  }

  has_assumption(boundcell: string): boolean {
    return this.assumptions.find((a) => a.BoundCell == boundcell) != undefined;
  }

  async setSimulationProperties(numberOfTrialsCell?: string, seedBoundCell?: string) {
    this.numberOfTrialsCell = numberOfTrialsCell;
    this.seedCell = seedBoundCell;
  }

  private add_assumptions(items: PersistedItems): boolean {
    if (!items.assumptions || !items.assumptions.length) {
      return false;
    }
    //remove existing
    let unchanged = this.assumptions.filter((a) => items.assumptions.find((ia) => a.BoundCell != ia.BoundCell));
    items.assumptions.forEach((ia) => unchanged.push(ia));
    this.assumptions = unchanged;
    return true;
  }

  private add_collapsed_state(items: PersistedItems): boolean {
    if (!items.collapsedState) {
      return false;
    }

    this.collapsedState = items.collapsedState;
    return true;
  }

  private add_correlations(items: PersistedItems): boolean {
    if (!items.correlations || !items.correlations.length) {
      return false;
    }

    let unchanged = this.correlations.filter((a) => items.correlations.find((ia) => a.BoundCell != ia.BoundCell));
    items.correlations.forEach((ia) => unchanged.push(ia));
    this.correlations = unchanged;
    return true;
  }

  private add_decisions(items: PersistedItems): boolean {
    if (!items.decisions || !items.decisions.length) {
      return false;
    }
    //remove existing
    let unchanged = this.decisions.filter((a) => items.decisions.find((ia) => a.BoundCell != ia.BoundCell));
    items.decisions.forEach((ia) => unchanged.push(ia));
    this.decisions = unchanged;
    return true;
  }

  private add_forecasts(items: PersistedItems): boolean {
    if (!items.forecasts || !items.forecasts.length) {
      return false;
    }
    //remove existing
    let unchanged = this.forecasts.filter((f) => items.forecasts.find((fi) => f.BoundCell != fi.BoundCell));
    items.forecasts.forEach((fi) => unchanged.push(fi));
    this.forecasts = unchanged;
    return true;
  }
  private add_number_of_trials_cell(cell: string): boolean {
    if (!cell || this.numberOfTrialsCell === cell) {
      return false;
    }
    this.numberOfTrialsCell = cell;
    return true;
  }
  private add_seed_cell(cell: string): boolean {
    if (!cell || this.seedCell === cell) {
      return false;
    }
    this.seedCell = cell;
    return true;
  }

  add(items: PersistedItems): boolean {
    let changed = this.add_assumptions(items);

    if (this.add_decisions(items)) {
      changed = true;
    }

    if (this.add_forecasts(items)) {
      changed = true;
    }
    if (this.add_number_of_trials_cell(items.numberOfTrialsCell)) {
      changed = true;
    }
    if (this.add_seed_cell(items.seedCell)) {
      changed = true;
    }
     
    if (this.add_correlations(items)) {
      changed = true;
    }

    if (this.add_collapsed_state(items)) {
      changed = true;
    }
    return changed;
  }

  private remove_assumptions(items: PersistedItems): boolean {
    if (!items.assumptions || !items.assumptions.length) {
      return false;
    }
    //remove existing
    let unchanged = this.assumptions.filter((a) => items.assumptions.find((ia) => a.BoundCell != ia.BoundCell));
    this.assumptions = unchanged;
    return true;
  }

  private remove_correlations(items: PersistedItems): boolean {
    if (!items.correlations || !items.correlations.length) {
      return false;
    }

    let unchanged = this.correlations.filter((a) => !items.correlations.find((ia) => a.BoundCell === ia.BoundCell));
    this.correlations = unchanged;
    return true;
  }

  private remove_decisions(items: PersistedItems): boolean {
    if (!items.decisions || !items.decisions.length) {
      return false;
    }
    //remove existing
    let unchanged = this.decisions.filter((a) => items.decisions.find((ia) => a.BoundCell != ia.BoundCell));
    this.decisions = unchanged;
    return true;
  }
  private remove_forecasts(items: PersistedItems): boolean {
    if (!items.forecasts || !items.forecasts.length) {
      return false;
    }
    //remove existing
    let unchanged = this.forecasts.filter((f) => items.forecasts.find((fi) => f.BoundCell != fi.BoundCell));
    this.forecasts = unchanged;
    return true;
  }
  private remove_number_of_trials_cell(cell: string): boolean {
    if (this.numberOfTrialsCell != cell) {
      return false;
    }
    this.numberOfTrialsCell = "";
    return true;
  }
  private remove_seed_cell(cell: string): boolean {
    if (this.seedCell != cell) {
      return false;
    }
    this.seedCell = "";
    return true;
  }

  remove(items: PersistedItems): boolean {
    let changed = this.remove_assumptions(items);

    if (this.remove_decisions(items)) {
      changed = true;
    }

    if (this.remove_forecasts(items)) {
      changed = true;
    }
    if (this.remove_number_of_trials_cell(items.numberOfTrialsCell)) {
      changed = true;
    }
    if (this.remove_seed_cell(items.seedCell)) {
      changed = true;
    }
    if (this.remove_correlations(items)) {
      changed = true;
    }
    return changed;
  }
}
