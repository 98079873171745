export enum ToastTypeEnum {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface IToastAction {
  label: string;
  onClick: () => void;
}

export interface IDispatchToast {
  type: ToastTypeEnum;
  message: string;
  action?: IToastAction;
}
