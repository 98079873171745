import React, { FC, useEffect } from 'react';
import * as Yup from "yup";
import { useCardStyles } from '../styles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearBoundCell, CorrelationParam, getCellsAddressRange, setCorrelationAtCell, tryCatch, interceptError, validateActiveSheet } from '../../taskpane';
import { ICorrelation, removeCorrelation, setCorrelationProperties } from '../../redux/slices/variables-slice';
import { useFormik } from 'formik';
import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Label,
  Option,
} from '@fluentui/react-components';
import { SaveRegular } from '@fluentui/react-icons';
import DropdownWithLabel from '../DropdownWithLabel';
import InputWithLabel from '../InputWithLabel';
import { excelCellRegex } from '../../constants/regex';
import useCellUniqueness from '../../hooks/useCellUniqueness';
import { correlationParamsDisplayName, CorrelationParamsEnum } from '../../enums/correlations';
import { DistributionsEnum } from '../../enums/distributions';
import usePreventNavigation from '../../hooks/usePreventNavigation';
import DeleteButton from '../DeleteButton';
import ToggleCollapsedButton from '../ToggleCollapsedButton';
import isEmpty from 'lodash/isEmpty';
import { normalizeExcelCell } from '../../helpers/normalize-cell';
import FocusErrorOnSubmit from '../FocusErrorOnSubmit';

interface ICorrelationCardProps {
  id: string;
  disabled?: boolean;
  onDelete: () => void;
}

function isCorrelationtEmpty(correlation: ICorrelation) {
  if (!isEmpty(correlation.cell)) {
    return false;
  }

  const filledValue = correlation.correlationParams?.find(p => !isEmpty(p.assumptionCell));
  return !filledValue;
}

const ExpandedCorrelationCard: FC<ICorrelationCardProps> = ({ id, disabled, onDelete }) => {
  const styles = useCardStyles();
  const dispatch = useAppDispatch();

  const {
    checkForecastCellsUniqueness,
    checkAssumptionCellsUniqueness,
    checkAssumptionDistributionParametersUniqueness,
    checkDecisionCellsUniqueness,
    checkDecisionParametersUniqueness,
    checkCorrelationUniqueness,
  } = useCellUniqueness();

  const storeCorrelation = useAppSelector((state) => state.variables.correlations
    .find((correlation) => correlation.id === id)
  );

  // TODO - another option is to return an error if an assumption with CUSTOM distribution is used
  // so that the used is properly notified
  const assumptions = useAppSelector(state => state.variables.assumptions
    .filter(assumption => !!assumption.cell
      && assumption.distribution !== DistributionsEnum.CUSTOM)
  );

  const form = useFormik({
    initialValues: {
      cell: storeCorrelation.cell || "",
      correlationParams: storeCorrelation.correlationParams || [
        { name: CorrelationParamsEnum.FIRST_ASSUMPTION, assumptionCell: "" },
        { name: CorrelationParamsEnum.SECOND_ASSUMPTION, assumptionCell: "" }
      ]
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      cell: Yup.string()
        .matches(excelCellRegex, "Invalid Excel cell format")
        .test("unique", "This cell is already used for another correlation", checkCorrelationUniqueness(id))
        .test("unique", "This cell is already used for a forecast", checkForecastCellsUniqueness())
        .test("unique", "This cell is already used for an assumption", checkAssumptionCellsUniqueness())
        .test("unique", "This cell is already used for a distribution parameters", checkAssumptionDistributionParametersUniqueness())
        .test("unique", "This cell is already used for another decision", checkDecisionCellsUniqueness())
        .test("unique", "This cell is already used for another decision parameter", checkDecisionParametersUniqueness())
        .required("Required"),
      correlationParams: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          assumptionCell: Yup.string().required("Required"),
        })
      ).test("unique", (values, ctx) => {
        if (values.length !== 2) {
          return true;
        }

        const [firstAssumption, secondAssumption] = values;
        if (firstAssumption.assumptionCell && secondAssumption.assumptionCell) {
          let isValid = firstAssumption.assumptionCell !== secondAssumption.assumptionCell;
          if (isValid) {
            return true;
          } else {
            return ctx.createError({
              path: 'correlationParams[0].assumptionCell',
              message:  "Assumptions have to be different"
            })
          }
        }

        return false;
      })
    }),
    onSubmit: (values) => {
      tryCatch(async () => {
        await validateActiveSheet();

        if (storeCorrelation.cell) {
          await clearBoundCell(storeCorrelation.cell);
        }

        await interceptError(async () => await setCorrelationAtCell(
          id,
          normalizeExcelCell(values.cell),
          values.correlationParams.map(param => new CorrelationParam(
            param.name,
            param.assumptionCell,
          ))
        ));

        dispatch(setCorrelationProperties({ id, ...values }));

        form.resetForm({ values });
      });
    },
  });

  usePreventNavigation(form.dirty);

  return (
    <form onSubmit={form.handleSubmit}>
      <FocusErrorOnSubmit form={form} />
      <Card>
        <CardHeader
          header={
            <Body1 className={styles.header}>
              <b>Correlation</b>
              <div className={styles.cardActions}>
                <ToggleCollapsedButton
                  id={id}
                  disabled={form.dirty || isCorrelationtEmpty(form.values as ICorrelation)}
                />
                <DeleteButton disabled={disabled} onDelete={onDelete}>
                  Remove Correlation
                </DeleteButton>
              </div>
            </Body1>
          }
        />
        <Body1>
          <div>
            <InputWithLabel
              id={`${id}-cell`}
              name="cell"
              label="Factor*"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              disabled={disabled}
              value={form.values.cell}
              error={form.touched.cell && form.errors.cell}
              buttonAction={() => {
                tryCatch(async () => {
                  const range = await getCellsAddressRange();
                  form.setFieldValue("cell", range, true);
                });
              }}
              aria-required
              required
            />
          </div>
          <Label style={{ marginTop: "1rem", display: "block" }}>Correlation parameters</Label>
          <div className={styles.flex_even}>
            {form.values.correlationParams.map((param, index) => (
              <DropdownWithLabel
                key={param.name}
                id={`correlation-${id}-${param.name}`}
                name={`correlationParams[${index}].assumptionCell`}
                label={correlationParamsDisplayName[param.name] + '*'}
                disabled={disabled}
                placeholder="Select an assumption"
                onOptionSelect={(_, data) => {
                  form.setFieldValue(`correlationParams[${index}].assumptionCell`, data.optionValue);
                }}
                onBlur={form.handleBlur}
                value={form.values.correlationParams[index].assumptionCell}
                error={
                  (form.touched.correlationParams?.[index]?.assumptionCell &&
                    (form.errors?.correlationParams?.[index] as any)?.assumptionCell) ||
                  undefined
                }
                aria-required
              >
                {assumptions.map((assumption) => (
                  <Option
                    key={assumption.cell}
                    value={assumption.cell}
                  >
                    {`${assumption.cell} - ${assumption.distribution}`}
                  </Option>
                ))}
              </DropdownWithLabel>
            ))}
          </div>
          {typeof form.errors.correlationParams === 'string' && (
            <div className={styles.error}>{form.errors.correlationParams}</div>
          )}
        </Body1>

        <CardFooter>
          <Button icon={<SaveRegular fontSize={16} />} type="submit">
            Save
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}

export default ExpandedCorrelationCard;