import { Button } from '@fluentui/react-components';
import { BinRecycleRegular } from '@fluentui/react-icons';
import React, { FC } from 'react';

interface Props {
  onDelete: () => void;
  disabled?: boolean;
  children?: string;
}

const DeleteButton: FC<Props> = ({ onDelete, children, disabled }) => {
  return (
    <Button
      icon={<BinRecycleRegular fontSize={16} />}
      disabled={disabled}
      onClick={onDelete}
      title={children}
    />
  )
}

export default DeleteButton;