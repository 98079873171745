import React from "react";
import { Button, Divider, Title2 } from "@fluentui/react-components";
import { AddRegular } from "@fluentui/react-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addForecast } from "../../redux/slices/variables-slice";
import { usePageStyles } from "../styles";
import ForecastCard from "../../components/ForecastCard";
import useActionsDisabled from "../../hooks/useActionsDisabled";

const ForecastPage: React.FC = () => {
  const styles = usePageStyles();
  const forecasts = useAppSelector((state) => state.variables.forecasts);
  const dispatch = useAppDispatch();

  function handleAddForecast() {
    window.scrollTo({
      top: 96,
      behavior: "smooth",
    });
    dispatch(addForecast());
  }

  const actionsDisabled = useActionsDisabled();

  return (
    <div className={styles.root}>
      <div className={styles.newButtonSticky}>
        <div className={styles.newButtonContainer}>
          <Button className={styles.newButton} icon={<AddRegular fontSize={16} />} onClick={handleAddForecast} disabled={actionsDisabled}>
            Define New Forecast
          </Button>
        </div>
      </div>

      {forecasts.map((forecast) => (
        <ForecastCard key={forecast.id} id={forecast.id} disabled={actionsDisabled} />
      ))}

      <Divider />
    </div>
  );
};

export default ForecastPage;
