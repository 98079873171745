import { Button, Input, Label, makeStyles, tokens, useId } from "@fluentui/react-components";
import type { InputProps } from "@fluentui/react-components";
import { LinkRegular } from "@fluentui/react-icons";
import React from "react";

export interface InputWithLabelProps extends InputProps {
  id: string;
  label: string;
  name: string;
  error?: string | null;
  buttonAction?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    minWidth: "80px",
    maxWidth: "400px",
  },
  error: {
    color: tokens.colorStatusDangerForeground3,
  },
});

const InputWithLabel: React.FC<InputWithLabelProps> = ({ id, label, error, name, buttonAction, ...props }) => {
  const inputId = useId(id);
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Label htmlFor={inputId} size={props.size} disabled={props.disabled}>
        {label}
      </Label>
      <Input
        id={inputId}
        name={name}
        aria-invalid={!!error}
        aria-describedby={inputId + 'error'}
        contentAfter={
          buttonAction && (
            <Button
              appearance="transparent"
              size="small"
              title="Press to link cell"
              icon={<LinkRegular />}
              onClick={buttonAction}
              disabled={props.disabled}
            />
          )
        }
        {...props}
      />
      {error && (
        <div className={styles.error}>
          <span id={inputId + 'error'}>{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputWithLabel;
