import { Button, Divider } from '@fluentui/react-components';
import React, { FC, useEffect } from 'react';
import { usePageStyles } from '../styles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addCorrelation } from '../../redux/slices/variables-slice';
import { AddRegular } from '@fluentui/react-icons';
import CorrelationCard from '../../components/CorrelationCard';
import useActionsDisabled from '../../hooks/useActionsDisabled';

const CorrelationPage: FC = () => {
  const styles = usePageStyles();
  const dispatch = useAppDispatch();

  const correlations = useAppSelector((state) => state.variables.correlations);

  const actionsDisabled = useActionsDisabled();

  function handleAdd() {
    window.scrollTo({
      top: 96,
      behavior: "smooth",
    });
    dispatch(addCorrelation());
  }

  return (
    <div className={styles.root}>
      <div className={styles.newButtonSticky}>
        <div className={styles.newButtonContainer}>
          <Button className={styles.newButton} icon={<AddRegular fontSize={16} />} onClick={handleAdd} disabled={actionsDisabled}>
            Define New Correlation
          </Button>
        </div>
      </div>

      {correlations.map(correlation => (
        <CorrelationCard
          key={correlation.id}
          id={correlation.id}
          disabled={actionsDisabled}
        />
      ))}

      <Divider />
    </div>
  );
};

export default CorrelationPage;