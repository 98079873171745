import { Decision, Assumption, Forecast, ExternalCell } from "./types";
import { ParameterEvaluator } from "./probdist";
import { InvalidCellNumber, InvalidSimulationError } from "./errors";
import { getNthColumn } from "./utils";
import _ from "lodash";
import { buildNewFormula, CellMapper as CellMapper, getAllCellsUsedBy } from "./formula";

interface CellSingleValueMapping {
  [key: string]: number;
}
export class ExcelAssumptionUpdate implements ParameterEvaluator {
  private calculateMode: any;
  
  constructor(private context: Excel.RequestContext) {}

  async SaveMode() {
    //console.log("[DEBUG] SaveMode ...");
    this.context.application.load("calculationMode");
    await this.context.sync();
    //console.log("[DEBUG] Current calculation mode: " + this.context.application.calculationMode);
    this.calculateMode = this.context.application.calculationMode;
    this.context.application.calculationMode = Excel.CalculationMode.manual;
    await this.context.sync();
    //console.log("[DEBUG] SaveMode done");
  }

  async RestoreMode() {
    //console.log("[DEBUG] RestoreMode ...");
    //restore calculation mode
    this.context.application.calculationMode = this.calculateMode;
    this.context.application.load("calculationMode");
    await this.context.sync();
    //console.log("[DEBUG] RestoreMode done");
  }

  public Recalculate() {
    this.context.application.calculate(Excel.CalculationType.recalculate);
  }

  public UpdateAssumptions(assumptions: Assumption[], values: number[]) {
    assumptions.forEach((a, i) => {
      if (a.BoundCell.indexOf("!") >= 0) {
        let addressParts = a.BoundCell.split("!");
        let sheet = this.context.workbook.worksheets.getItem(addressParts[0]);
        let range = sheet.getRange(addressParts[1]);
        range.values = [[values[i]]];
        return;
      }
      let sheet = this.context.workbook.worksheets.getFirst(true);
      let range = sheet.getRange(a.BoundCell);
      range.values = [[values[i]]];
    });
  }

  public UpdateDecisions(decisions: Decision[], values: number[]) {
    decisions.forEach((a, i) => {
      if (a.BoundCell.indexOf("!") >= 0) {
        let addressParts = a.BoundCell.split("!");
        let sheet = this.context.workbook.worksheets.getItem(addressParts[0]);
        let range = sheet.getRange(addressParts[1]);
        range.values = [[values[i]]];
        return;
      }
      let sheet = this.context.workbook.worksheets.getFirst(true);
      let range = sheet.getRange(a.BoundCell);
      range.values = [[values[i]]];
    });
  }

  async ExtractForecastValues(forecasts: Forecast[]): Promise<number[]> {
    let forecastRanges = forecasts.map((f) => {
      if (f.BoundCell.indexOf("!") >= 0) {
        let addressParts = f.BoundCell.split("!");
        let sheet = this.context.workbook.worksheets.getItem(addressParts[0]);
        let range = sheet.getRange(addressParts[1]);
        range.load("values");
        return range;
      }
      let sheet = this.context.workbook.worksheets.getFirst(true);
      let range = sheet.getRange(f.BoundCell);
      range.load("values");
      return range;
    });
    await this.context.sync();
    return forecastRanges.map((range) => Number.parseFloat(range.values[0][0].toString())); //assumes single cell
  }

  async ExtractFormulas(cells: string[]): Promise<string[]> {
    let forecastRanges = cells.map((cell) => {
      if (cell.indexOf("!") >= 0) {
        let addressParts = cell.split("!");
        let sheet = this.context.workbook.worksheets.getItem(addressParts[0]);
        let range = sheet.getRange(addressParts[1]);
        range.load("formulas");
        return range;
      }
      let sheet = this.context.workbook.worksheets.getFirst(true);
      let range = sheet.getRange(cell);
      range.load("formulas");
      return range;
    });
    await this.context.sync();
    return forecastRanges.map((range) => range.formulas[0][0]); //assumes single cell
  }

  async single_values(boundCells: string[]): Promise<number[]> {
    let unresolved = this.get_unresolved(boundCells);

    if (!unresolved || unresolved.length == 0) {
      //return from cache
      return boundCells.map((c) => this.mapping[c]);
    }
    await this.resolve_single_values(unresolved);
    return boundCells.map((c) => this.mapping[c]);
  }

  async resolve_single_values(unresolved: string[]): Promise<void> {
    return await this.withManualCalculationMode(
      async () => {
        this.context.application.calculate(Excel.CalculationType.recalculate);
        await this.context.sync();
        console.log("eval params boundCells=", unresolved);
        let paramvalues = await this.evalparams(unresolved);

        console.log("eval params paramvalues=", paramvalues);
        let results = paramvalues.map((values) => values?.[0]?.[0]);
        console.log("eval params results=", results);

        const invalidValueIndex = results.findIndex(Number.isNaN);
        if (invalidValueIndex >= 0) {
          throw new InvalidCellNumber(`Cell ${unresolved[invalidValueIndex]} has invalid value`);
        }

        for (var i = 0; i < unresolved.length; i++) {
          this.mapping[unresolved[i]] = results[i];
        }
        return;
      },
      (err) => {
        console.log("error ", err);
        throw err;
      }
    );
  }

  async multiple_values(boundCells: string[]): Promise<number[][]> {
    await this.SaveMode();
    try {
      let vals = [];
      for(let index = 0; index < boundCells.length; ++index) {
        let sheet = this.context.workbook.worksheets.getFirst(true);

        if (boundCells[index]) {
          let range = sheet.getRange(boundCells[index]);

          range.load("values");
          await this.context.sync();
          let values = range.values.flatMap((items) => items?.map((v) => Number.parseFloat(v.toString())));
          vals[index] = values;

          const invalidValueIndex = values.findIndex(Number.isNaN);
          if (invalidValueIndex >= 0) {
            throw new InvalidCellNumber(`Cell range ${boundCells[index]} contains an invalid value`);
          }
        } else {
          vals[index] = [];
        }
      }

      console.log("multiple evalparams", boundCells, vals);
      return vals;
    } catch (err) {
      console.log("error ", err);
      throw err;
    } finally {
      await this.RestoreMode();
    }
  }

  async evalparams(boundCells: string[]): Promise<number[][][]> {
    this.context.application.calculate(Excel.CalculationType.recalculate);
    await this.context.sync();
    
    let ranges = boundCells?.map((boundCell) => {
      if (boundCell.indexOf("!") >= 0) {
        let addressParts = boundCell.split("!");
        let sheet = this.context.workbook.worksheets.getItem(addressParts[0]);
        let range = sheet.getRange(addressParts[1]);
        range.load("values");
        return range;
      }
      let sheet = this.context.workbook.worksheets.getFirst(true);
      let range = sheet.getRange(boundCell);
      range.load("values");
      return range;
    });
    await this.context.sync();
    let result = ranges?.map((r) => {
      try {
        let values: number[][] = r.values?.map((items) => items?.map((v) => Number.parseFloat(v.toString())));
        console.log(`evalparams ${values}`);
        return values;
      } catch {
        return [];
      }
    });
    return result;
  }

  async ExtractForecastValuesAndStats(
    sheet: Excel.Worksheet,
    forecasts: Forecast[],
    cellMapper: CellMapper,
    count = 1,
  ) {
    let dataRanges = forecasts.map((f) => {
      let dataRange = sheet.getRange(
        cellMapper.BuildRangeBetween(f.BoundCell, 1, f.BoundCell, count)
      );
      dataRange.load('values');
      return dataRange;
    });

    let statsRanges = forecasts.map((f) => {
      let { col } = cellMapper.GetMapping(f.BoundCell);
      let avgRange = sheet.getRange(`${col}${count + 1}`);
      avgRange.load('values');

      let stdevRange = sheet.getRange(`${col}${count + 2}`);
      stdevRange.load('values');

      let medianRange = sheet.getRange(`${col}${count + 3}`);
      medianRange.load('values');
      return { avgRange, stdevRange, medianRange };
    });

    await this.context.sync();

    return {
      forecastValues: dataRanges.map(dataRange => dataRange.values.map(v => v[0])),
      forecastStats: statsRanges.map(stats =>({
        avg: Number(stats.avgRange.values[0][0]),
        stdev: Number(stats.stdevRange.values[0][0]),
        median: Number(stats.medianRange.values[0][0])
      }))
    } 
  }

  async FillInDataForProcessing(
    sheet: Excel.Worksheet,
    forecasts: Forecast[],
    assumptions: Assumption[],
    decisions: Decision[],
    assumptionValues: number[][],
    trialDecisionValues: number[][],
  ): Promise<CellMapper> {
    let iterationsCount = assumptionValues.length;
    let cellMapper = new CellMapper()

    // Fill In Decisions
    if (decisions.length > 0) {
      cellMapper.MapDecisions(decisions);
      let first = cellMapper.GetMapping(_.first(decisions).BoundCell);
      let range = `${first.col}${1}:${getNthColumn(first.col, trialDecisionValues[0].length - 1)}${iterationsCount}`;
      const decisionValuesRange = sheet.getRange(range);
      decisionValuesRange.values = trialDecisionValues;
    }

    // Fill In Assumptions
    {
      cellMapper.MapAssumptions(assumptions);
      let assumptionStart = _.first(assumptions).BoundCell;
      let assumptionEnd = _.last(assumptions).BoundCell;
      const assumptionValuesRange = sheet.getRange(
        cellMapper.BuildRangeBetween(assumptionStart, 1, assumptionEnd, iterationsCount)
      );
      assumptionValuesRange.values = assumptionValues;
    }

    // Fill In All Cells Used By Formulas
    {
      let cellsToMove = await this.getAllCellsUsedBy(
        forecasts.map(f => f.BoundCell),
        cellMapper,
      );

      cellsToMove.forEach(({ cell, value, formula }) => {
        if (formula) {
          const newFormula = buildNewFormula(formula, cell => cellMapper.GetMapping(cell).cell);
          // Fill in formula into first row
          let firstRowFormulaRange = sheet.getRange(cellMapper.BuildRangeBetween(cell, 1, cell, 1));
          firstRowFormulaRange.formulas = [[newFormula]];

          // Copy firstRow formula to the rest of cells
          let restFormulasRange = sheet.getRange(cellMapper.BuildRangeBetween(cell, 2, cell, iterationsCount));
          restFormulasRange.copyFrom(firstRowFormulaRange, Excel.RangeCopyType.formulas, true, false);
          return;
        }

        // Set the value for each iteration
        let range = sheet.getRange(
          cellMapper.BuildRangeBetween(cell, 1, cell, iterationsCount)
        );
        range.values = new Array(iterationsCount).fill([value])
      });
    }

    // Fill In Forecast formulas
    forecasts.forEach((forecast) => {
        let { col: dstCol } = cellMapper.GetMapping(forecast.BoundCell);
        let dataCells = cellMapper.BuildRangeBetween(forecast.BoundCell, 1, forecast.BoundCell, iterationsCount);

        let avgFormula = `=AVERAGE(${dataCells})`;
        let avgRange = sheet.getRange(`${dstCol}${iterationsCount + 1}`);
        avgRange.formulas = [[ avgFormula ]];

        let stdevFormula = `=STDEV.S(${dataCells})`;
        let stdevRange = sheet.getRange(`${dstCol}${iterationsCount + 2}`);
        stdevRange.formulas = [[ stdevFormula ]];

        let medianFormula = `=MEDIAN(${dataCells})`;
        let medianRange = sheet.getRange(`${dstCol}${iterationsCount + 3}`);
        medianRange.formulas = [[ medianFormula ]];
    });

    return cellMapper;
  }

  CreateSheetForProcessing() {
    let sheets = this.context.workbook.worksheets;
    let sheet = sheets.add();
    return sheet;
  }

  async DeleteProcessingSheet(sheet: Excel.Worksheet) {
    sheet.delete();
    await this.context.sync();
  }

  private async withManualCalculationMode<T>(callback: () => T, reject: (err) => void = undefined) {
    try {
      let result = await callback();
      return result;
    } catch (err) {
      if (reject) reject(err);
      throw err;
    }
  }

  private mapping: CellSingleValueMapping = {};

  private get_unresolved(boundCells: string[]) {
    return boundCells.filter((c) => !Object.prototype.hasOwnProperty.call(this.mapping, c));
  }

  private async getAllCellsUsedBy (cells: string[], cellMapper: CellMapper): Promise<ExternalCell[]> {
    if (!cells.length) return [];

    let externalCells = [];

    let unmappedCellsFromFormulas: string[] = [];

    cellMapper.MapIntermediateCells(cells);

    const formulas = await this.ExtractFormulas(cells);

    formulas.forEach((formula, index) => {
      if (typeof formula === 'string' && formula[0] === '=') {
        externalCells.push({ cell: cells[index], formula });

        unmappedCellsFromFormulas = unmappedCellsFromFormulas.concat(
          getAllCellsUsedBy(formula).filter(newCell => !cellMapper.Has(newCell))
        );

        return;
      }

      externalCells.push({ cell: cells[index], value: formula });
    });

    
    externalCells = externalCells.concat(
      await this.getAllCellsUsedBy(unmappedCellsFromFormulas, cellMapper)
    );

    return externalCells;
  };
}
