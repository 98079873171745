import { createSlice } from "@reduxjs/toolkit";

const dirtyFormsSlice = createSlice({
  name: "dirtyForms",
  initialState: {
    count: 0,
  },
  reducers: {
    inc: (state) => {
      state.count += 1;
    },
    dec: (state) => {
      state.count -= 1;
    }
  }
});

export const dirtyFormsActions = dirtyFormsSlice.actions;

export default dirtyFormsSlice.reducer;