export const generateId = (length: number): string => {
  const result = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    const randomChar = characters.charAt(randomIndex);
    result.push(randomChar);
  }

  return result.join("");
};
