import React, { FC } from "react";
import ExpandedForecastCard from "./ExpandedForecastCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clearBoundCell, tryCatch } from "../../taskpane";
import { removeForecast } from "../../redux/slices/variables-slice";
import CollapsedForecastCard from "./CollapsedForecastCard";

interface Props {
  id: string;
  disabled?: boolean;
}

const ForecastCard: FC<Props> = ({ id, disabled }) => {
  const collapsed = useAppSelector((state) =>
    state.collapsed[id]
  );

  const dispatch = useAppDispatch();

  const storeForecast = useAppSelector((state) => state.variables.forecasts.find((forecast) => forecast.id === id));

  const handleRemoveForecast = () => {
    tryCatch(async () => {
      if (storeForecast.cell) await clearBoundCell(storeForecast.cell);
      dispatch(removeForecast({ id }));
    });
  };

  return (
    <>
      {!collapsed && (
        <ExpandedForecastCard
          id={id}
          onDelete={handleRemoveForecast}
          disabled={disabled}
        />
      )}
      {collapsed && (
        <CollapsedForecastCard
          id={id}
          disabled={disabled}
          onDelete={handleRemoveForecast}
        />
      )}
    </>
  );
};

export default ForecastCard;
