import { IDispatchToast } from "../interfaces/dispatch-toast";

export const dispatchToast: (props: IDispatchToast) => void = ({ type, message, action }) => {
  const toastEvent = new CustomEvent("toasterEvent", {
    detail: {
      type,
      message,
      action,
    },
  });

  document.dispatchEvent(toastEvent);
};
