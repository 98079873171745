import React, { FC } from 'react';
import ExpandedAssumptionCard from "./ExpandedAssumptionCard";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import CollapsedAssumptionCard from './CollapsedAssumptionCard';
import useCellUniqueness from '../../hooks/useCellUniqueness';
import { clearBoundCell, tryCatch, updateAssociatedCorrelations } from '../../taskpane';
import { getCellsUsedByAssumption } from '../../helpers/cells-used';
import { removeAssumption } from '../../redux/slices/variables-slice';

interface Props {
  id: string;
  disabled?: boolean;
}

const AssumptionCard: FC<Props> = ({ id, disabled }) => {
  const collapsed = useAppSelector((state) =>
    state.collapsed[id]
  );

  const dispatch = useAppDispatch();
  const { checkUniqueness } = useCellUniqueness();

  const storeAssumption = useAppSelector((state) =>
    state.variables.assumptions.find((assumption) => assumption.id === id)
  );

  const handleRemoveAssumption = () => {
    tryCatch(async () => {
      for (const cell of getCellsUsedByAssumption(storeAssumption)) {
        if (checkUniqueness(storeAssumption.id)(cell)) {
          await clearBoundCell(cell);
        }
      }

      await updateAssociatedCorrelations(storeAssumption.cell)

      dispatch(removeAssumption({ id }));
    });
  };

  return (
    <>
      {collapsed && (
        <CollapsedAssumptionCard
          id={id}
          onDelete={handleRemoveAssumption}
          disabled={disabled}
        />
      )}
      {!collapsed && (
        <ExpandedAssumptionCard
          id={id}
          onDelete={handleRemoveAssumption}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default AssumptionCard;
